import { createContext, useState, useEffect, useLayoutEffect } from "react";
import { isLoggedIn, setAuthTokens, clearAuthTokens, getAccessToken, getRefreshToken } from 'axios-jwt'
import { axiosInstance } from '../auth/api'
import axios from "axios";
import { useQuery } from "react-query";


const UserContext = createContext();

export const UserProvider = ({children})=>{
    const [isLoggedInState, setisLoggedInState] = useState('')
    //const tempLoggedin = isLoggedIn()
    //setisLoggedInState(tempLoggedin);
    //below state is for login/Signup modal control
    const [isOpenLoginSignupModal, setisOpenLoginSignupModal] = useState(false);
    const closeLoginSignupModal = () => setisOpenLoginSignupModal(false);
    const openLoginSignupModal = () => setisOpenLoginSignupModal(true);

    /**Global User State */
    const [userData, setuserData] = useState({
        id: null,
        firstName: null,
        lastName: null,
        dob: null,
        email: null,
        mobile: null,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        countryCode: null,
        createdAt: null,
        isLoggedIn: true,
        isMobileVerified: false,
    });
  
    const signupUser = async (fisrtName, lastName, email, password, confirmPassword)=>{
        try{
        const { data, status } = await axiosInstance.post(`/signup`, {
                    firstName:fisrtName,
                    lastName:lastName,
                    email:email,
                    password:password,
                    confirmPassword: confirmPassword,
                },{
                    // headers: {
                    //     'Access-Control-Allow-Origin' : '*',
                    // },
                });
                let returnResponse = {
                    "status":status,
                    "message": (status===500)?`There's an error, try again after sometime`
                    :(status===200)?`${data.message}`
                    :(status===400)?`${data.errors}`
                    :"There's something wrong",
                    };
                if(status===200){
                    setAuthTokens({
                        accessToken: data.access_token,
                        refreshToken: data.refresh_token
                      })
                    return returnResponse;
                }else{
                    return returnResponse;
                }
        }catch(error){

            console.log("catch is working", error)
            let retunResponse=[];
            if(error?.response?.status===400){
                return retunResponse={
                    "status":error?.response?.status,
                    "message": error?.response?.data?.errors[0],
                    };
            }else if(error?.response?.status===500){
                return retunResponse={
                    "status":error?.response?.status,
                    "message": "500 Internal Server Error",
                    };
            }else{
                return retunResponse={
                    "status":error?.response?.status,
                    "message": `${error?.response?.status} Error`,
                    };
            }
        }
    }
    //proxy url from cloudflare workers: https://old-glade-bf42.dandbdubaicdn.workers.dev/corsproxy/?apiurl=
    /**login login start */
    //Post email and password and get tokens in return. Call setAuthTokens with the result.
    const loginUser = async (email, password)=>{
        try{
        const  response  = await axiosInstance.post('/login',
                {
                    email:email,
                    password:password,
                },
                //{withCredentials: true}
                //     {
                //     // headers: {
                //     //     'Access-Control-Allow-Origin' : 'http://localhost:3000',
                //     // },
                // },
                );
                console.log(response?.data);
                let returnResponse = {
                    "status":response?.status,
                    "message": (response?.status===500)?`There's an error, try again after sometime`
                    :(response.status===200)?`${response.data.message}`
                    :(response.status===400)?`${response.data.errors}`
                    :"There's something wrong",
                    };
                
                if(response?.status===200){
                    console.log("🚀 ~ file: UserContext.jsx ~ line 102 ~ loginUser ~ response.status", response.status)
                    setAuthTokens({
                        accessToken: response.data.access_token,
                        refreshToken: response.data.refresh_token
                      });
                    //   getUserData();
                    return returnResponse;
                }else{
                    return returnResponse;
                }
        }catch(error){
            console.log(error?.response)
            let retunResponse=[];
            if(error?.response?.status===400){
                return retunResponse={
                    "status":error?.response?.status,
                    "message": error?.response?.data?.errors[0],
                    };
            }else if(error?.response?.status===500){
                return retunResponse={
                    "status":error?.response?.status,
                    "message": "500 Internal Server Error",
                    };
            }else{
                return retunResponse={
                    "status":error?.response?.status,
                    "message": `${error?.response?.status} Error`,
                    };
            }
        }
    }

    /**login logic end */


    /**get user details start */
    
    const {data: getUserData, isLoading: getUserLoading, isSuccess: getUserisSuccess } = useQuery(
        ['getUserDetails', isLoggedIn()],  async()=>{
        if(isLoggedIn()){
        try{
            const { data, status } = await axiosInstance.get(`/get_user`, 
                    {
                        // headers: {
                        //     'Access-Control-Allow-Origin' : 'http://localhost:3000',
                        // },
                    },
                    );
                    if(status===200){
                            return data.result;
                    }else{
                        return data.error[0];
                    }
            }catch(error){
                console.log("get user error",error?.response)
            }
        }
    },{
        //staleTime: 900000,
    }
       );
    /**get user details end */

     /**login login start */
    const logoutUser = async ()=>{
        try{
        let refreshToken = getRefreshToken()
        const { data, status } = await axios.post(`https://frontend-connect.bogportal.com/logout`,
        {},
                    {
                    headers: {
                    'Authorization': `Bearer ${refreshToken}` 
                    }
                });
                clearAuthTokens()
        }catch(error){
            console.log(error.response)
            clearAuthTokens()
        }
    }    
    

    return(
        <UserContext.Provider value={{
            userData,
            signupUser,
            loginUser,
            logoutUser,
            getUserData,
            getUserisSuccess,
            getUserLoading,
            isOpenLoginSignupModal,
            closeLoginSignupModal,
            openLoginSignupModal,
            isLoggedInState,
        }}> {children}</UserContext.Provider> 
    )
}


export default UserContext;