import React, { ChangeEvent, Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import { Range } from "rc-slider";
import convertNumbThousand from "utils/convertNumbThousand";
import FilterContext from "context/FilterContext";
import axios from "axios";
import { useQuery } from "react-query";
import { isTemplateExpression } from "typescript";
import ncNanoId from "utils/ncNanoId";
import Button from "shared/Button/Button";
 

interface amenitiesItem {
  name: string,
  value: number,
  ischecked: boolean,
}
interface AmenitiesFilterTemp  {
  [name: string]: any[];
};

const TabFilters = () => {
  const [isOpenAmenitiesFilter, setisOpenAmenitiesFilter] = useState(false);
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  const  { updateSearchFilter, FilterData }  = useContext(FilterContext);
  const bedroomsinitialValue = 1;
  
  const [isActiveTypeofPlace, setisActiveTypeofPlace] = useState(false);
  const [isActiveRangeofPrices, setisActiveRangeofPrices] = useState(false);
  const [isActiveBedrooms, setisActiveBedroome] = useState(false);
  const [isActiveAmenities, setisAmenities] = useState(false);
  const [isActiveSort, setisActiveSort] = useState(false);

/**Get Amenities started */
  const getAmenities = async () => {
    const { data } = await axios.get('https://frontend-connect.bogportal.com/amenities', {
      headers: {
        'Access-Control-Allow-Origin' : '*',
      },
    });
    return data.result;
  }
  const {data: getAmenitiesData, isLoading: getAmenitiesLoading, isSuccess: getAmenitiesisSuccess } = useQuery('getAmenities', getAmenities);

    // const EssentialsAmenities: any[] = [];
    // const FeaturesAmenities: any[] = [];
    // const SafetyAmenities: any[] = [];
    // const LocationAmenities: any[] = [];

    const [AmenitiesFilter, setAmenitiesFilter] = useState<any[]>([]);
    const AmenitiesFilterTemp: { name: string; value: string, category: string, ischecked: boolean }[] = [...AmenitiesFilter];

    useLayoutEffect(() => {
      getAmenitiesisSuccess && getAmenitiesData.forEach((e: { name: any; id: any; category: any; }) =>{
        /* eslint-disable */
        AmenitiesFilterTemp.push({'name': e.name, 'value': e.id, 'category':e.category ,'ischecked':false});
      });
    setAmenitiesFilter(AmenitiesFilterTemp);
    }, [getAmenitiesData])

    // useEffect(() => {
    //   AmenitiesFilter?.map((e: any) =>{
    //     /* eslint-disable */
    //       (e.category=='Essentials') ? EssentialsAmenities.push({'name': e.name, 'value': e.id,'category':e.category , 'ischecked':false}) : '';
    //       (e.category=='Features') ? FeaturesAmenities.push({'name': e.name, 'value': e.id,'category':e.category , 'ischecked':false}): '';
    //       (e.category=='Safety') ? SafetyAmenities.push({'name': e.name, 'value': e.id,'category':e.category , 'ischecked':false}): '';
    //       (e.category=='Location') ? LocationAmenities.push({'name': e.name, 'value': e.id,'category':e.category , 'ischecked':false}): '';
      
    //     });
    //     console.log("essenstial:",EssentialsAmenities);
    // }, [AmenitiesFilter])
 
    const handleAmenitiesFilter = (e: ChangeEvent<HTMLInputElement>, option: any) =>{
      const AmenitiesFilterList = [...AmenitiesFilter];
      AmenitiesFilterList.forEach(chkItem=>{
        if(chkItem.value === option.value){
          chkItem.ischecked = e.target.checked;
        }
      })
      setAmenitiesFilter(AmenitiesFilterList);
    }

    const AmenitiesFilterApply = ()=>{
      const AmenitiesFiltervalues = [...AmenitiesFilter];
      let CheckAmenitiesFiltervalues: number[] = [];
      AmenitiesFiltervalues.forEach(chkItem=>{
        if(chkItem.ischecked == true){
          CheckAmenitiesFiltervalues.push(chkItem.value);
        }
      });
      if (AmenitiesFiltervalues.length != 0 ){
        updateSearchFilter(CheckAmenitiesFiltervalues.join(','),'amenities');
        setisAmenities(true);
      }
    }

    const AmenitiesFilterClear = ()=>{
      const AmenitiesFilterList = [...AmenitiesFilter];
      AmenitiesFilterList.forEach(chkItem=>{
          chkItem.ischecked = false;
      })
      setAmenitiesFilter(AmenitiesFilterList);
      updateSearchFilter('','amenities');
      setisAmenities(false);
    }

/**Get Amenities ended- displayed in the filter */
 
  


/**Type of Place Filter Logic Start */

  const [typeofplaceCheckbox, settypeofplaceCheckbox] = useState([
    { 
      name: 'Apartment',
      value: 1,
      ischecked: false
    },
    { 
      name: 'Villa',
      value: 8,
      ischecked: false
    }
  ]);
  /**studio filter checkbox */
  const [studioFilter, setstudioFilter] = useState({ 
    name: 'Studio',
    value: 0,
    ischecked: false
  });
  const handleTypeofplaceFilter = (e: ChangeEvent<HTMLInputElement>, option: any) =>{
    // const indexvalue = typeofplaceCheckbox.findIndex(i => i === option);
    // const newoption = option;
    // newoption.ischecked = e.target.checked;
    // let newArr = [...typeofplaceCheckbox]; // copying the old datas array
    // newArr[indexvalue] = newoption; 
    // settypeofplaceCheckbox(newArr);
    const typeofcheckboxList = [...typeofplaceCheckbox];
    typeofcheckboxList.forEach(chkItem=>{
      if(chkItem.value === option.value){
        chkItem.ischecked = e.target.checked;
      }
    })
    settypeofplaceCheckbox(typeofcheckboxList);
  }

  const TypeofplaceFilterApply = ()=>{
    const typeofplacesvalues = [...typeofplaceCheckbox];
    let checkplacesvalues: number[] = [];
    typeofplacesvalues.forEach(chkItem=>{
      if(chkItem.ischecked == true){
      checkplacesvalues.push(chkItem.value);
      }
    });
    if (checkplacesvalues.length != 0 ){
      updateSearchFilter(checkplacesvalues.join(','),'property_type');
      setisActiveTypeofPlace(true);
    }
  }

  const TypeofplaceFilterClear = ()=>{
    const typeofcheckboxList = [...typeofplaceCheckbox];
    typeofcheckboxList.forEach(chkItem=>{
        chkItem.ischecked = false;
    })
    settypeofplaceCheckbox(typeofcheckboxList);
    updateSearchFilter('','property_type');
    setisActiveTypeofPlace(false);
  }

  /**Type of Place Filter Logic End */

  /**SortFilter Filter Logic Start */

  const [SortFilter, setSortFilter] = useState([
    { 
      name: 'Featured',
      value: 'featured',
      ischecked: false
    },
    {
      name: 'Popular',
      value: 'popular',
      ischecked: false
    },
    { 
      name: 'Price: High to low',
      value: 'price_high_to_low',
      ischecked: false
    },,
    { 
      name: 'Price: Low to High',
      value: 'price_low_to_high',
      ischecked: false
    }
  ]);
  const [SortFilterByPriceValue, setSortFilterByPriceValue] = useState<any>();
  
  const handleSortFilter = (e: ChangeEvent<HTMLInputElement>) =>{
    // const indexvalue = typeofplaceCheckbox.findIndex(i => i === option);
    // const newoption = option;
    // newoption.ischecked = e.target.checked;
    // let newArr = [...typeofplaceCheckbox]; // copying the old datas array
    // newArr[indexvalue] = newoption; 
    // settypeofplaceCheckbox(newArr);
    // const SortFilterList = [...SortFilter];
    // SortFilterList.forEach(chkItem=>{
    //   if(chkItem.value === option.value){
    //     chkItem.ischecked = e.target.checked;
    //   }
    // });
    // setSortFilter(SortFilterList);
    setSortFilterByPriceValue(e.target.value);
    //console.log(e.target.value);
  }

  const SortFilterApply = ()=>{
    
    if (SortFilterByPriceValue!= ''){
      if(SortFilterByPriceValue == 'featured' || SortFilterByPriceValue == 'popular'){
        updateSearchFilter(SortFilterByPriceValue,'sort');
        setisActiveSort(true);
      }
      else if(SortFilterByPriceValue == 'price_low_to_high' || SortFilterByPriceValue == 'price_high_to_low'){
        updateSearchFilter(SortFilterByPriceValue,'sortByPrice');
      }
      
    }
  }

  const SortFilterClear = ()=>{
    setSortFilterByPriceValue('');
    updateSearchFilter('','sort');
    setisActiveSort(false);
  }

  /**SortFilter Filter Logic End */

  /**property bedrooms Filter Logic Start */
const [bedroomValue, setbedroomValue] = useState(0)//bedroomsinitialValue);
console.log({bedroomValue})

const handleStudioFilter = (e: ChangeEvent<HTMLInputElement>) =>{
  setstudioFilter((prevState) => ({...prevState, ischecked: e.target.checked,}));
}
const handleBedroomFilter = (value: number)=>{
  if(value>=1){
    setstudioFilter((prevState) => ({...prevState, ischecked: false,}))
    setbedroomValue(value);
  }
    
}

const BedroomsFilterApply = () =>{
  if(studioFilter.ischecked){
    updateSearchFilter(studioFilter.value,'bedrooms');
  }else{
    updateSearchFilter(bedroomValue,'bedrooms');
  }
  setisActiveBedroome(true);
}

const BedroomsFilterClear = () =>{
  setstudioFilter((prevState) => ({...prevState, ischecked: false,}));
  setbedroomValue(prevbedroomValue => prevbedroomValue - prevbedroomValue);
  updateSearchFilter('','bedrooms');
  setisActiveBedroome(false);
}
  /**property bedrooms Filter Logic End */

  /**property price Filter Logic Start */
  const [rangePrices, setRangePrices] = useState([0, 20000]);


const PriceFilterApply = () =>{
  updateSearchFilter(rangePrices,'rangePrices');
  setisActiveRangeofPrices(true);
}

const PriceFilterClear = () =>{
  setRangePrices([0,20000]);
  updateSearchFilter([0,20000],'rangePrices');
  setisActiveRangeofPrices(false);

}
  /**property price Filter Logic End */

  /**Clear All filter logic start*/
  const handleClearAllFilterApply=()=>{
    TypeofplaceFilterClear();
    AmenitiesFilterClear();
    SortFilterClear();
    BedroomsFilterClear();
    PriceFilterClear();
    updateSearchFilter('','clearAllFilters');
  }
  /**clear All filter logic end */

  /**Mobile Filter Logic start */
  const MobileFilterApply = ()=>{
    TypeofplaceFilterApply();
    AmenitiesFilterApply();
    SortFilterApply();
    BedroomsFilterApply();
    PriceFilterApply();
  }
  const MobileFilterClear = ()=>{
    TypeofplaceFilterClear();
    AmenitiesFilterClear();
    SortFilterClear();
    BedroomsFilterClear();
    PriceFilterClear();
  }
  /**Mobile Filter Logic end */

  //
  const closeModalAmenitiesFilter = () => setisOpenAmenitiesFilter(false);
  const openModalAmenitiesFilter = () => setisOpenAmenitiesFilter(true);
  //
  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full 0 text-white flex items-center bg-primary-500 justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabsTypeOfPlace = () => {
    return (
      <>
    
      <Popover className="relative">
        {({ open, close}) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }${isActiveTypeofPlace && "!border-primary-500 text-primary-700"}`}
            >
              <span>Type of place</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
              <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeofplaceCheckbox.map((item) => (
                      <div key={item.value} className={`flex text-sm sm:text-base `}>
                      <input
                        id={item.name}
                        name={item.name}
                        type="checkbox"
                        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                        checked={item.ischecked}
                        data-value={item.value}
                        onChange={(e)=> handleTypeofplaceFilter(e, item)}
                      />
                        <label
                          htmlFor={item.name}
                          className="ml-3.5 flex flex-col flex-1 justify-center"
                        >
                          <span className=" text-neutral-900 dark:text-neutral-100">
                            {item.name}
                          </span> 
                            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">                           
                            </p>                         
                        </label>     
                    </div>                 
                    ))}
                  </div>
                  
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={()=>{
                      TypeofplaceFilterClear();
                      close();
                  }} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{
                        TypeofplaceFilterApply();
                        close();
                    }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                  </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      </>
    );
  };

  const renderTabsRoomAndBeds = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }${isActiveBedrooms && "!border-primary-500 text-primary-700"}`}
            >
              <span>Bedrooms</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900   border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                  <div className={`flex text-sm sm:text-base `}>
                      <label
                          htmlFor="studio"
                          className="flex flex-col flex-1 justify-center"
                        >
                          <span className="font-medium text-neutral-800 dark:text-neutral-200">
                            Studio
                          </span>                         
                        </label>
                      <input
                        id="studioFilter"
                        name="studioFilter"
                        type="checkbox"
                        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                        checked={studioFilter.ischecked}
                        data-value={0}
                        onChange={(e)=> handleStudioFilter(e)}
                      />
                             
                    </div>  
                    <NcInputNumber label="Bedrooms" min={1} max={10} defaultValue={bedroomValue} onChange={(value)=>handleBedroomFilter(value)}/>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={()=>{
                      BedroomsFilterClear();
                      close();
                  }} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{
                        BedroomsFilterApply();
                        close();
                    }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 focus:outline-none 
              ${isActiveRangeofPrices && "!border-primary-500 text-primary-700"}`}
            >
              <span>
                {`AED${convertNumbThousand(
                  rangePrices[0]
                )} - AED${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              {/*renderXClear()*/}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price per day</span>
                      <Range
                        className="text-red-400"
                        min={0}
                        max={20000}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={setRangePrices}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              AED
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              AED
                            </span>
                          </div>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={()=>{
                      PriceFilterClear()
                      close();
                    }} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{
                        PriceFilterApply();
                        close();
                    }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderMoreFilterItem = (
    data: {
      name: string;
      defaultChecked?: boolean;
      value?: string | number;
      category?: string;
      ischecked?: boolean; 
    }[],
    type?: string,
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <div key={item.value} className={`flex text-sm sm:text-base `}>
            <input
              id={item.name}
              name={item.name}
              type="checkbox"
              className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
              checked={item.ischecked}
              data-value={item.value}
              onChange={(type=="typeofplace")?(e)=> handleTypeofplaceFilter(e, item):(e)=> handleAmenitiesFilter(e, item)}
            />
              <label
                htmlFor={item.name}
                className="ml-3.5 flex flex-col flex-1 justify-center"
              >
                <span className=" text-neutral-900 dark:text-neutral-100">
                  {item.name}
                </span> 
                  <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">                           
                  </p>                         
              </label>     
          </div>
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <div key={item.value} className={`flex text-sm sm:text-base `}>
            <input
              id={item.name}
              name={item.name}
              type="checkbox"
              className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
              checked={item.ischecked}
              data-value={item.value}
              onChange={(type=="typeofplace")?(e)=> handleTypeofplaceFilter(e, item):(e)=> handleAmenitiesFilter(e, item)}
            />
              <label
                htmlFor={item.name}
                className="ml-3.5 flex flex-col flex-1 justify-center"
              >
                <span className=" text-neutral-900 dark:text-neutral-100">
                  {item.name}
                </span> 
                  <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">                           
                  </p>                         
              </label>     
          </div> 
          ))}
        </div>
      </div>
    );
  };

  const renderAmenitiesFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 focus:outline-none cursor-pointer
          ${isActiveAmenities && "!border-primary-500 text-primary-700"}`}
          onClick={openModalAmenitiesFilter}
        >
          <span>Amenities</span>
          {/* {renderXClear()} */}
        </div>

        <Transition appear show={isOpenAmenitiesFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalAmenitiesFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      More filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={()=>{
                        closeModalAmenitiesFilter()}} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(AmenitiesFilter)}
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={()=>{
                        AmenitiesFilterClear();
                        closeModalAmenitiesFilter()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{
                        AmenitiesFilterApply();
                        closeModalAmenitiesFilter()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderClearAllFilter = () => {
    if(FilterData.property_type != ""|| FilterData.min_price!=0 || FilterData.bedrooms!="" ||
      FilterData.amenities!=""|| FilterData.sort!=""){
      
    return (
            <button
              onClick={()=>handleClearAllFilterApply()}
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none border-primary-500 text-primary-700`}
            >
              <span>Clear All</span>
              <i className="las la-times ml-2"></i>
            </button> 
    );
    }
    
  };

  const renderSortingFilter = () => {
    return (
      <>
    
      <Popover className="relative">
        {({ open, close}) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }${isActiveSort && "!border-primary-500 text-primary-700"}`}
            >
              <span>Sort</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-xs px-4 mt-3 right-0 sm:px-0 lg:max-w-xs">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
              <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {SortFilter.map((item) => (
                      <div key={item?.value} className={`flex text-sm sm:text-base `}>
                      <input
                        id={item?.name}
                        name="sort-by-price"
                        type="radio"
                        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                        checked={SortFilterByPriceValue === item?.value}
                        value={item?.value}
                        onChange={(e)=> handleSortFilter(e)}
                      />
                        <label
                          htmlFor={item?.name}
                          className="ml-3.5 flex flex-col flex-1 justify-center"
                        >
                          <span className=" text-neutral-900 dark:text-neutral-100">
                            {item?.name}
                          </span>
                            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">                           
                            </p>                         
                        </label> 
                    </div>                 
                    ))}
                  </div>
                  
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={()=>{
                        SortFilterClear();
                        close()}} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{
                        SortFilterApply();
                        close()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                  </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      </>
    );
  };

  const renderTabMoreFilterMobile = () => {
    return (
      <div>
        <div
          className={`flex lg:hidden items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500  text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilterMobile}
        >
          <span>More filters</span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      More filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Type of place</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(typeofplaceCheckbox, "typeofplace")}
                        </div>
                      </div>

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Range
                                className="text-red-400"
                                min={0}
                                max={2000}
                                defaultValue={[0, 1000]}
                                allowCross={false}
                                onChange={setRangePrices}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      AED
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      AED
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Bedrooms</h3>
                        <div className="mt-6 relative flex flex-col space-y-5">
                          <NcInputNumber label="Bedrooms" max={10} defaultValue={bedroomValue} onChange={setbedroomValue}/>
                        </div>
                      </div>

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(AmenitiesFilter)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={()=>{
                        MobileFilterClear();
                        closeModalMoreFilterMobile()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={()=>{
                        MobileFilterApply();
                        closeModalMoreFilterMobile()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex flex-row justify-between lg:space-x-4">
      <div className="hidden lg:flex space-x-4 items-start">
        {renderTabsTypeOfPlace()}
        {renderTabsPriceRage()}
        {renderTabsRoomAndBeds()}
        {renderAmenitiesFilter()}
        {renderClearAllFilter()}
      </div>
      {renderTabMoreFilterMobile()}
      <div className="space-x-4 items-end">
      {renderSortingFilter()}
      </div>
    </div>
  );
};

export default TabFilters;
function indexOf(chitem: { name: string; value: number; ischecked: boolean; }): unknown {
  throw new Error("Function not implemented.");
}

