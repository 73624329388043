import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Heading from "components/Heading/Heading";

export interface PROCESS_WORKS {
    title: string;
}
const PROCESS_WORKS: PROCESS_WORKS[]= [
  {title:"1. Free Consultation"},
  {title:"2. BeOurGuest Registers Property with DTCM"},
  {title:"3. Free Interior Design Quotation"},
  {title:"4. Furnishing and Photography"},
  {title:"5. Marketing Campaign"},
  {title:"6. Guest Books Holiday Home"},
  {title:"7. Concierge Services Provided"},
  {title:"8. Concierge Services Provided"},
  {title:"9. Guest Enjoys Stay"},
  {title:"10. Maintenance and Clean-Up Upon Leave"},
]
export interface ListYourPropertyProps {
  className?: string;
}

const ListYourProperty: FC<ListYourPropertyProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-ListYourProperty overflow-hidden relative ${className}`}
      data-nc-id="ListYourProperty"
    >
      <Helmet>
        <title>List Your Property - Be Our Guest Dubai</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div
      className={`nc-SectionHero relative`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            List Your Property
          </h1>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          Be Our Guest provides a range of premium services to ensure your property is handled with the care it deserves at all times – from the legal proceedings all the way to customer and ROI management.
          </span>
          {/* {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>} */}
        </div>
        <div className="flex-grow">
          <img className="w-full rounded-xl" src="https://images.pexels.com/photos/584399/living-room-couch-interior-room-584399.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
        </div>
      </div>
      
    </div>

        <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left pt-20">
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
            <h3 className="text-lg font-semibold text-neutral-900 pb-4 md:text-xl dark:text-neutral-200">Why You Should Transform Your Property into a Holiday Home</h3>
            Be Our Guest offers homeowners access to one of the most booming tourist markets in the Middle East and a door to the extra stream of passive income. Our specialist team ensures that your property is registered and aligned with the relevant government entities, lavishly furnished, and leased on short-term or year-round basis. </span>
        </div>
        {/**Benefits */}
        <div
            className={`nc-SectionOurFeatures relative flex flex-col items-center lg:flex-row`}
            data-nc-id="SectionOurFeatures"
            >
            <div className="flex-grow">
                <NcImage src={`https://images.unsplash.com/photo-1626707639862-2dd9fd7372ce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGFpcmJuYiUyMGhvbWVzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&h=900&w=800&q=60`} />
            </div>
            <div
                className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 lg:pl-16`}
            >
                
                <h2 className="font-semibold text-4xl mt-5">What You Benefit? </h2>
                <span className="text-sm text-gray-400 tracking-widest">
                Short-term rentals in Dubai market earn you up to 15% to 20% more income than the traditional rental market.
                </span>

                <ul className="space-y-10 mt-10">
                <li className="space-y-4">
                    <Badge name="well-maintained" />
                    <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                    Property is well-maintained and in pristine condition after every guest vacancy
                    </span>
                </li>
                <li className="space-y-4">
                    <Badge color="green" name="Financial Management" />
                    <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                    Management of all financial transactions, payments, reservations, and concierge services</span>
                </li>
                <li className="space-y-4">
                    <Badge color="red" name="Administration" />
                    <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                    Administrative coordination with all governmental departments and service providers
                    </span>
                </li>
                <li className="space-y-4">
                    <Badge color="purple" name="Marketing" />
                    <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                    Professional marketing services guarantee property is successfully advertised</span>
                </li>
                <li className="space-y-4">
                    <Badge name="Security" />
                    <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                    Installed digital locks ensure security of property and safety of guests</span>
                </li>
                <li className="space-y-4">
                    <Badge color="green" name="Maintenance" />
                    <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                    Upholding operational duties including but not limited to cleaning, laundry, and maintenance</span>
                </li>
                </ul>
            </div>
        </div>
        <div className="nc-Section relative">
            <h2 className="font-semibold text-4xl mt-5">REAL Scope of Services</h2>
            <div className="grid grid-flow-row-dense grid-cols-1 grid-rows-3 mt-12 sm:grid-cols-3">
            { PROCESS_WORKS.map((item, index) => (
                <div key={index} className="my-4 mr-6 bg-slate-100 p-4 rounded-xl dark:bg-neutral-800">
                    <span className="text-base">{item.title}</span>
                </div>
            ))}
            </div>
        </div>
        
        <SectionHowItWork
          data={[
            {
              id: 1,
              img: "https://images.pexels.com/photos/935743/pexels-photo-935743.jpeg?auto=compress&cs=tinysrgb&w=400",
              title: "List your Property",
              desc: "Connect with our consultants & list your property in the market with us",
            },
            {
              id: 2,
              img: "https://images.pexels.com/photos/4846097/pexels-photo-4846097.jpeg?auto=compress&cs=tinysrgb&w=400",
              title: "property Management",
              desc: "We will manage everything from styling your home to listing online till handeling guests.",
            },
            {
              id: 3,
              img: "https://images.pexels.com/photos/774095/pexels-photo-774095.jpeg?auto=compress&cs=tinysrgb&w=400",
              title: "Relax & Earn",
              desc: "We will evaluate your home, and run a pricing algorithm to maximize your earnings",
            },
          ]}
        />
        {/* <SectionPerks /> */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="ListYourProperty_" />
        </div>

        {/* <SectionStatistic /> */}

        {/*<SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default ListYourProperty;
