import React, { FC } from "react";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

export interface Card3SmallProps {
  className?: string;
  post: any;
}

const Card3Small: FC<Card3SmallProps> = ({ className = "h-full", post }) => {

  return (
    <div
      className={`nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center ${className}`}
      data-nc-id="Card3Small"
    >
      <Link to={`/blog/${post?.post_slug}`} className=" absolute inset-0" title={post?.title?.rendered}></Link>
      <div className="relative space-y-2">
        <PostCardMeta meta={{ ...post }} />
        <Link to={`/blog/${post?.post_slug}`} className=" line-clamp-2" title={post?.title?.rendered}>
          <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100" 
          dangerouslySetInnerHTML={{ __html: post?.title?.rendered }}>
        </h2>
        </Link>
      </div>

      <Link
        to={`/blog/${post?.post_slug}`}
        title={post?.title?.rendered}
        className={`block sm:w-20 flex-shrink-0 relative rounded-lg overflow-hidden mb-5 sm:ml-4 sm:mb-0 group`}
      >
        <div className={`w-full h-0 aspect-w-16 aspect-h-9 sm:aspect-h-16`}>
          <NcImage
            containerClassName="absolute inset-0"
            className="nc-will-change-transform object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
            src={post?.fimg_url}
            title={post?.title?.rendered}
          />
        </div>
      </Link>
    </div>
  );
};

export default Card3Small;
