import React, { FC, Fragment, useContext, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import UserContext from "context/UserContext";
import { Transition, Dialog } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const [isOpenResponseModal, setisOpenResponseModal] = useState(false);
  const closeResponseModal = () => setisOpenResponseModal(false);
  const openResponseModal = () => setisOpenResponseModal(true);
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const [errors, seterrors] = useState({
    email: '',
  })
  const [loginResponse, setloginResponse] = useState<any>({
    status: '',
    message: '',
  })
  const  { loginUser }  = useContext(UserContext);

  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errorstemp = errors;

    switch (name) {
      case 'email': 
      errorstemp.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
            setemail(value);
            seterrors((prevState) => ({...prevState, [name]: errorstemp.email,}));
        break;
      case 'password': 
            setpassword(value);
        break;
      default:
        break;
    }
  }

  const handleLogin = (e:any)=>{
    e.preventDefault();
    if(validateForm(errors)) {
      console.info('Valid Form');
      setisLoading(true);
      loginUser(email,password).then((res: any)=>{
        console.log("login res",res)
        setisLoading(false);
        if(res.status===400){
          setloginResponse((prevState: any) => ({...prevState, status: res.status,message:res.message,}));
          openResponseModal();
        }else if(res.status===500){
          setloginResponse((prevState: any) => ({...prevState, status: res.status,message:res.message,}));
          openResponseModal();
        }else if(res.status===200){
          navigate("/", { replace: true });
        }
      });
      
    }else{
      console.error('Invalid Form');
    }
  }

  const renderLoginModal = () => {
    return (
      <div>
        <Transition appear show={isOpenResponseModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeResponseModal}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-1/2"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-1/2 max-w-3xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
                  
                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium text-center">{
                        (loginResponse.status===400)?
                      `😕 ${loginResponse.message}`
                      :(loginResponse.status===500)?
                      "☹️ Login Failed, try again."
                      :"🙄"
                      }</h3>
                      </div>
                      
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={()=>{
                        closeResponseModal()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Close
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login | Be Our Guest | Dubai</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-secondary-300 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                autoComplete="username"
                onChange={handleChange}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" name="password" autoComplete="current-password" onChange={handleChange} className="mt-1" />
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
      {renderLoginModal()}
    </div>
  );
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors: { email:string }) => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

export default PageLogin;



