import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

import NcImage from "shared/NcImage/NcImage";
import Heading from "shared/Heading/Heading";


export interface InteriorDesignProps {
  className?: string;
}

const InteriorDesign: FC<InteriorDesignProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-InteriorDesign overflow-hidden relative ${className}`}
      data-nc-id="InteriorDesign"
    >
      <Helmet>
        <title>Interior Design - Be Our Guest Dubai</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div
        className={`nc-SectionHero relative`}
        data-nc-id="SectionHero"
        >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
          Why Good Interior Design Matters?
          </h1>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          Luxurious minimalism. Earthly tones. Urban décor. The art of designing your home using the right tools, colors, elements, lighting, appliances, and furniture plays an integral role in the atmosphere you imbue into your property. An interior designer can help you create increased value for your property.
          </span>
        {/* {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>} */}
        </div>
        <div className="flex-grow ml-4">
          <img className="w-full rounded-xl" src="https://images.pexels.com/photos/1648768/pexels-photo-1648768.jpeg?auto=compress&cs=tinysrgb&w=800" alt="Interior Design" />
        </div>
      </div>
    </div>
    <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left pt-4">
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
            Within one meeting, our specialized interior design team aids in discovering the right atmospheric niche for a home and advises on the best suited décor with state-of-the-art materials. With BeOurGuest, you can transform your vision from a mere thought into a glorious manifestation of your dreams.             </span>
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
            Our expert team takes all factors of design into consideration when it comes to ensuring balance and harmony in your home – from a complementary color palette, to utilizing shapes and lines, to picking out the correct textures. You envisage, we deliver.            </span>
    </div>

    <div className="nc-SectionFounder relative my-32">
        <Heading desc="A selection of pristine, expertly designed homes with high-end amenities and services">
                Other Real Estate Services We Offer
        </Heading>
        <div className={`nc-Section relative flex flex-col lg:flex-row lg:items-center my-32`}>
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
                <h2 className="font-semibold text-4xl">Light & Lighting</h2>
                <p className="block mt-5 text-neutral-500 dark:text-neutral-400">
                Lighting design is the art of using light to create moods, emotions, and atmospheres. Lighting can be used to highlight specific areas of your room, add drama, and even change the ambiance. 
                <br></br>
                There are many different types of lighting fixtures that can be used in any space. Some examples include recessed lights, track lighting, pendant lighting, table lamps, floor lamps, and sconces. Natural sunlight will create an airy and light feeling, but it may also cause the room to be excessively warmer in temperature. 
                <br></br>
                When designing your home interior, lighting should be considered from the beginning to accommodate the rest of the elements. You can use different types of lights like LED, CFL, incandescent bulbs, etc. to create the right ambience for your room.
                </p>
            </div>
            <div className="flex-grow">
                <NcImage className="rounded-2xl" src="https://images.pexels.com/photos/1571468/pexels-photo-1571468.jpeg?auto=compress&cs=tinysrgb&w=600&h=400" />
            </div>
        </div>
        <div className={`nc-Section relative flex flex-col lg:flex-row lg:items-center my-32`}>
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5 ">
                <h2 className="font-semibold text-4xl">Colour & Shade</h2>
                <p className="block mt-5 text-neutral-500 dark:text-neutral-400">
                Color is an important component of interior design. Colors are used to make rooms look bigger or smaller, warmer or cooler.
                <br></br>
                While a darker shade can add dramatism and character to a room, it can also make it appear smaller than it is; thus, an interior designer would take into consideration if the room accommodates windows, or if they can balance out the darker shade through lightly colored and textured furniture. 
                <br></br>
                Colour can also be used to highlight certain features of a room. In order to achieve the desired effect, choose colors using the secondary color wheel that complement each other.
                </p>
            </div>
            <div className="flex-grow">
                <NcImage className="rounded-2xl" src="https://images.pexels.com/photos/1930310/pexels-photo-1930310.jpeg?auto=compress&cs=tinysrgb&w=600&h=400" />
            </div>
        </div>
        <div className={`nc-Section relative flex flex-col lg:flex-row lg:items-center my-32`}>
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5 ">
                <h2 className="font-semibold text-4xl">Texture & Shape</h2>
                <p className="block mt-5 text-neutral-500 dark:text-neutral-400">
                The texture and shape of your furniture plays an important role in creating a personality and ambiance within a space. The cool-toned, reflective surfaces used in a hotel lobby vastly differs from the jagged, plush textures one would use in a home. 
                <br></br>
                Textured surfaces give off a feeling of warmth and comfort. They can also provide visual interest and intrigue. A textured surface can be achieved through different materials and textures. Examples include wood, stone, glass, metal, and even concrete. 
                <br></br>
                Shape is another element that can be used to define a space. Shapes can be geometric, organic, or abstract. Geometric shapes are clean, simple, and easy to understand. Organic shapes have a natural look and feel to them. Abstract shapes are not easily defined, but they may be beautiful and interesting.
                </p>
            </div>
            <div className="flex-grow">
                <NcImage className="rounded-2xl" src="https://images.pexels.com/photos/1643383/pexels-photo-1643383.jpeg?auto=compress&cs=tinysrgb&w=600&h=400" />
            </div>
        </div>
        <div className="my-32">Request a free consultation with our holiday home team: +971 4 871 9200 or info@beourguest.ae</div>
    </div>
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="InteriorDesign_" />
        </div>
    
    </div>
    </div>
  );
};

export default InteriorDesign;
