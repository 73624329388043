import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: any[];
  heading: ReactNode;
  subHeading?: ReactNode;
  isTabsLoadingSuccess?:boolean;
  onClickTab: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "🎈 Latest Articles",
  isTabsLoadingSuccess,
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: any) => {
    console.log(item)
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {isTabsLoadingSuccess && tabs?.filter((_: any, idx: number) => idx < 4).map((item, index) => (
            <NavItem
              key={index}
              isActive={(index===0 && tabActiveState==='')?true:tabActiveState === item?.nameSlug}
              onClick={() => handleClickTab(item?.nameSlug)}
            >
              {item?.name}
            </NavItem>
          ))}
        </Nav>
        <span className="hidden sm:block flex-shrink-0">
        <Link to={`/short-term-rentals/dubai/`} ><ButtonSecondary className="!leading-none">
            <span>View all</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary></Link>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;
