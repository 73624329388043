import React, { FC, useState } from "react";
import "react-dates/initialize";
import StaySearchForm from "./StaySearchForm";


export type SearchTab = "Stays";

export interface HomeSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Stays";
}

const HomeSearchForm: FC<HomeSearchFormProps> = ({
    className,
    currentPage,
}) => {

    const isArchivePage = !!currentPage;

  return (
    <div
      className={`nc-HomeSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HomeSearchForm"
    >
      {<StaySearchForm haveDefaultValue={isArchivePage} />}
    </div>
  );
};

export default HomeSearchForm;
