import { Dialog, Tab, Transition } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import React, { FC, Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import BookingContext from "context/BookingContext";
import moment from "moment";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import GuestsInput, { GuestsInputProps } from "components/HeroSearchForm/GuestsInput";
import useWindowSize from "hooks/useWindowResize";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LoginSignup from "containers/LoginSignup/LoginSignup";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { isLoggedIn } from "axios-jwt";
import PhoneInput from "react-phone-input-2";
import { axiosInstance } from "auth/api";
import OtpInput from 'react-otp-input';

export interface CheckOutPageProps {
  className?: string;
}
type priceDetails = {
  totalNights?: number | null,
  totalNightsFee?: number | null,
  pricePerNight?: number | null,
  cleaningFee?: number | null,
  damageDeposit?: number | null,
  monthlyDiscount?: number | null,
  weeklyDiscount?: number | null,
  TotalPrice?: number | null,
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  /**OTP states */
  const [isOpenOTPDialog, setisOpenOTPDialog] = useState(false);

  const closeOTPDialog = () => {
    setisOpenOTPDialog(false)
    seOptValue("")
    setInvalidCodeError(false)
  };


  const openOTPDialog = () => setisOpenOTPDialog(true);



  /**OTP states end */
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });


  const [guestValue, setGuestValue] = useState<GuestsInputProps["defaultValue"]>({ guestAdults: 2, guestChildren: 0 });
  const [PriceDetails, setPriceDetails] = useState<priceDetails>({
    totalNights: null,
    totalNightsFee: null,
    pricePerNight: null,
    cleaningFee: null,
    damageDeposit: null,
    monthlyDiscount: null,
    weeklyDiscount: null,
    TotalPrice: null,
  });

  let [priceDetailsisLoading, setpriceDetailsisLoading] = useState(true);

  const [aroundBlockedDates, setaroundBlockedDates] = useState("");

  const [optValue, seOptValue] = useState("");
  const [invalidCodeError, setInvalidCodeError] = useState(false);
  const [optInvalid, setOptInvalid] = useState(true);
  const [transactionID, setTransactionID] = useState("");
  const [portalURL, setPortalURL] = useState("");
  const [payBtnLoading, setpayBtnLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("AE");
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [paymentFormError, setPaymentFormError] = useState(false);
  const [paymentFormErrorMsg, setPaymentFormErrorMsg] = useState("");
  
  const [paymentFormSucess, setPaymentFormSucess] = useState(false);
  const [paymentFormSucessMsg, setPaymentFormSucessMsg] = useState("");

  

  const [billingDetails, setbillingDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: ""
  })

  const [cardDetails, setCardDetails] = useState({
    cvc: "",
    expiryMonth: "",
    expiryYear: "",
    cardNumber: "",
  })


  const windowSize = useWindowSize();

  const { calculatedBooking } = useContext(BookingContext);
  const [searchParams] = useSearchParams();
  const slug = searchParams.get('property');
  const startDate = searchParams.get('sd');
  const endDate = searchParams.get('ed');
  const guests = searchParams.get('guests');
  const propertyId = searchParams.get('propid');



  //get calendar
  const { data: getCalendar, isLoading: getCalendarisLoading, isSuccess: getCalendarisSuccess } = useQuery(
    ['getCalendar'], async () => {
      if (propertyId != null) {
        try {
          const { data } = await axios.get(`https://frontend-connect.bogportal.com/calendar/${propertyId}`, {
            headers: {
              'Access-Control-Allow-Origin': '*',
            }
          });
          let blockedDates: any[] = [];
          data.result.forEach((dateItem: { isAvailable: any; date: moment.MomentInput; }) => {
            (!dateItem.isAvailable) && blockedDates.push(moment(dateItem.date))
          });
          return blockedDates;
        }
        catch (error) {
          console.log("error getting calendar", error.response.status);
        }
      }
    },
    { cacheTime: 900000, }
  );




  /* api call for filter listings*/
  const { data: getSingleListingCheckoutData, isLoading: getSingleListingCheckoutLoading, isSuccess: getSingleListingCheckoutisSuccess } = useQuery(
    ['getSingleListingCheckout'],
    async () => {
      try {
        const { data } = await axios.get(`https://frontend-connect.bogportal.com/listings/?slug=${slug}`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        });
        return data.response;
      }
      catch (error) {
        console.log("getListing", error.response.status);
      }
    },
    {
      cacheTime: 900000,
      enabled: !!slug,
    },
  );








  useEffect(() => {
    if (startDate != null && endDate != null && guests != null && getCalendarisSuccess === true) {
      checkParamsBlockedDatesInBtw(startDate, endDate).then(response => {
        //below reponse value confirms if the selected dates have blocked dates in between & updates the state based on condition.
        if (!response) {
          setSelectedDate((prevState: any) => ({ ...prevState, startDate: moment(startDate), endDate: moment(endDate), }));
          setGuestValue((prevState: any) => ({ ...prevState, guestAdults: guests, }));
        }
      }
      )
    }

  }, [getCalendar])





  //get calculated price and set it
  useLayoutEffect(() => {
    if (selectedDate.startDate != null && selectedDate.endDate != null) {
      //below will update the PriceDetails state which holds all calculation values
      if (guestValue.guestAdults != 0) {
        setpriceDetailsisLoading(true);
        setaroundBlockedDates("");
        calculatedBooking(propertyId, selectedDate.startDate, selectedDate.endDate, guestValue.guestAdults).then((data: any) => {
          setPriceDetails((prevState: any) => ({ ...prevState, totalNights: selectedDate.startDate?.diff(selectedDate.endDate, 'days'), }));
          setPriceDetails((prevState: any) => ({ ...prevState, totalNightsFee: data.price, }));
          setPriceDetails((prevState: any) => ({ ...prevState, cleaningFee: data.cleaningFee, }));
          setPriceDetails((prevState: any) => ({ ...prevState, damageDeposit: data.refundableDamageDeposit, }));
          setPriceDetails((prevState: any) => ({ ...prevState, monthlyDiscount: data.monthlyDiscount, }));
          setPriceDetails((prevState: any) => ({ ...prevState, weeklyDiscount: data.weeklyDiscount, }));
          setPriceDetails((prevState: any) => ({ ...prevState, TotalPrice: data.totalPrice, }));
        });
        setpriceDetailsisLoading(false);
      }
      checkParamsBlockedDatesInBtw(moment(selectedDate.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"), moment(selectedDate.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")).then(response => {
      })


    }
    //validate min stays & show message
    else if (selectedDate.startDate != null && selectedDate.endDate != null && selectedDate.startDate?.diff(selectedDate.endDate, 'days') < getSingleListingCheckoutData.minNights) {
      setaroundBlockedDates(`Min stay is ${getSingleListingCheckoutData.minNights}, kindly change your dates`);
    }//clear price details state
    else if (selectedDate.startDate == null || selectedDate.endDate == null || guestValue.guestAdults == 0) {
      clearPriceDetailsState();
    }

  }, [selectedDate.startDate, selectedDate.endDate, guestValue.guestAdults]);




  const clearPriceDetailsState = () => {
    setPriceDetails((prevState) =>
    ({
      ...prevState,
      totalNights: null,
      totalNightsFee: null,
      pricePerNight: null,
      cleaningFee: null,
      damageDeposit: null,
      monthlyDiscount: null,
      weeklyDiscount: null,
      TotalPrice: null,
    }));
  }




  //check is start date & end date has blocked dates in between & then clear pricing state
  const checkParamsBlockedDatesInBtw = async (startDate: string, endDate: string) => {
    let areSelectedDatesAroundBlockedDates = false;
    await getCalendar?.every((blockedDate) => {
      // console.log(moment(blockedDate).format('YYYY-MM-DD'))
      if (moment(moment(blockedDate).format('YYYY-MM-DD')).isBetween(startDate, endDate, null, '[]')) {
        areSelectedDatesAroundBlockedDates = true;
        setaroundBlockedDates('Your selecting dates around blocked dates & change your dates.');
        selectedDate.startDate != null && setSelectedDate((prevState) => ({ ...prevState, startDate: null, }));
        setSelectedDate((prevState) => ({ ...prevState, endDate: null, }));
        setGuestValue((prevState) => ({ ...prevState, guestAdults: 0, }));
        setPriceDetails((prevState) =>
        ({
          ...prevState,
          totalNights: null,
          totalNightsFee: null,
          pricePerNight: null,
          cleaningFee: null,
          damageDeposit: null,
          monthlyDiscount: null,
          weeklyDiscount: null,
          TotalPrice: null,
        }));
        return false;
      }
      return true;
    })
    return areSelectedDatesAroundBlockedDates;
  }




  //check is start date & end date has blocked dates in between & then clear pricing state - end
  /**stay rangeDate component props logic */
  const findBlockedDay = (day: moment.Moment) => {
    let found: any;
    if (getCalendarisSuccess) {
      found = getCalendar?.some((blockedday: any) => blockedday.isSame(day, 'day'));
    }
    if (found === true) {
      return found;
    }
    if (found === undefined) {
      return undefined;
    }
    else {
      return false;
    }
  }





  /**stay rangeDate component props logic end */
  const guestInputHandler = (data: any) => {
    setGuestValue(data);
    if (data.guestAdults == 0) {
      clearPriceDetailsState();
    }
  }















  const handlePayment = async (e: any) => {
    e.preventDefault();

    setpayBtnLoading(true)
    try {
      const { data, status } = await axiosInstance.post('/payment',
        {
          "propertyId": 113393,
          "isVerified": !optInvalid,
          "phone": billingDetails.phone,
          "arrivalDate": startDate,
          "departureDate": endDate,
          "guestsNumber": guests,
          "amount": { "currencyCode": "AED" },
          "billingAddress": {
            "firstName": billingDetails.firstName,
            "lastName": billingDetails.lastName,
            "address1": billingDetails.streetAddress,
            "state": billingDetails.state,
            "city": billingDetails.city,
            "countryCode": countryCode,
            "zipcode": billingDetails.zipCode
          },
          "emailAddress": billingDetails.email,
          "cardDetails": {
            "CardNumber": cardDetails.cardNumber,
            "ExpiryMonth": cardDetails.expiryMonth,
            "ExpiryYear": cardDetails.expiryYear,
            "VerifyCode": cardDetails.cvc
          }
        }
      )
      if (status === 200) {
        setpayBtnLoading(false)
        setPaymentFormSucess(true)
        setPaymentFormSucessMsg(data.message)
      }
      else {
        return setPaymentFormErrorMsg(data.errors)
      }
    }
    catch (e) {
      setpayBtnLoading(false)
      setPaymentFormError(true)
      setPaymentFormErrorMsg(e.response.data.errors[0])
    }
  }



  //opt logic
  const renderBookingPaymentModal = () => {
    return (
      <form action={portalURL} method="post">
        <div className="max-w-lg mx-auto space-y-6">
          <div className="block text-neutral-700 dark:text-neutral-300">
            <input type='Hidden' name='TransactionID' defaultValue={transactionID} />
            <div className=" w-full flex flex-col sm:rounded-2xl ">
              <div className="flex flex-col sm:flex-row sm:items-center pb-3">
                <div className="flex-shrink-0 w-full sm:w-40">
                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                    <NcImage src={getSingleListingCheckoutData?.listingImages[0]?.url} />
                  </div>
                </div>
                <div className="py-5 sm:px-5 space-y-3">
                  <div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      {`${getSingleListingCheckoutData?.suburb}, ${getSingleListingCheckoutData?.state}`}
                    </span>
                    <span className="text-base font-medium mt-1 block">
                      {getSingleListingCheckoutData?.name.slice(getSingleListingCheckoutData?.name.indexOf('·') + 1)}
                    </span>
                  </div>
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    {`${getSingleListingCheckoutData?.bedroomsNumber} bedrooms · ${getSingleListingCheckoutData?.bedroomsNumber} baths`}
                  </span>
                </div>
              </div>

              <div className=" flex flex-col space-y-4 my-5">
                {
                  (PriceDetails.totalNightsFee != null && PriceDetails.totalNights != null) ?
                    <>
                      <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>AED {Math.abs(PriceDetails.totalNightsFee / PriceDetails.totalNights).toFixed(2)} x {Math.abs(PriceDetails.totalNights)} night</span>
                        <span>AED {PriceDetails.totalNightsFee}</span>
                      </div>
                      <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Cleaning Fee</span>
                        <span>AED {PriceDetails.cleaningFee}</span>
                      </div>
                      <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Damage Deposit</span>
                        <span>AED {PriceDetails.damageDeposit}</span>
                      </div>

                      <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                      <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>AED {PriceDetails.TotalPrice}</span>
                      </div>
                    </>
                    :
                    <>
                      <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span><Skeleton count={1} height={10} width={100} /></span>
                      </div>
                      <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span><Skeleton count={1} height={10} width={100} /></span>
                      </div>

                      <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                      <div className="flex justify-between font-semibold">
                        <span><Skeleton count={1} height={10} width={100} /></span>
                      </div>
                    </>
                }
              </div>
            </div>

            <div className="text-center">
              <ButtonPrimary loading={payBtnLoading} type="submit">Pay</ButtonPrimary>
            </div>

          </div>
        </div>
      </form>
    )
  }


  const handleOtpInput = (val: any) => {
    seOptValue(val);
    setInvalidCodeError(false)
  }

  const generateOtp = async () => {
    if (billingDetails.phone.length > 6) {
      setisOpenOTPDialog(true);
      try {
        const response = await axiosInstance.post('/generate_otp', {
          "phone": billingDetails.phone,
        });
        console.log('generate_otp');

      } catch (error) {
        console.log('generate_otp: ', error?.response)
      }
    } else {
      setPhoneNumberInvalid(true);
    }
  }

  const handleVerifyOtp = async () => {
    setVerifyOtpLoading(true);
    console.log('verify_otp_request: ', optValue);
    try {
      const response = await axiosInstance.post('/verify_otp', {
        "otp": optValue
      });
      console.log('verify_otp_response: ', response.data.isVerified);
      if (response.data.isVerified) {
        setOptInvalid(false);
        setVerifyOtpLoading(false);
        setisOpenOTPDialog(false);
      } else {
        setOptInvalid(true);
        setInvalidCodeError(true)
      }
    } catch (error) {
      console.log('verify_otp: ', error?.response.isVerified)
    }
  }



  const handlePhoneChange = (e: any, item: any) => {
    setPhoneNumberInvalid(false)
    setCountryCode(item.countryCode.toUpperCase())
    setOptInvalid(true);
    setbillingDetails((prevState: any) => ({ ...prevState, phone: e }))
  }

  const handleCardNumber = (event: any) => {
    setPaymentFormError(false)
    setCardDetails((prevState: any) => ({ ...prevState, cardNumber: event.target.value, }))
  }

  const handleExpiryMonth = (event: any) => {
    setPaymentFormError(false)
    setCardDetails((prevState: any) => ({ ...prevState, expiryMonth: event.target.value, }))
  }

  const handleExpiryYear = (event: any) => {
    setPaymentFormError(false)
    setCardDetails((prevState: any) => ({ ...prevState, expiryYear: event.target.value, }))
  }

  const handleExpiryCvc = (event: any) => {
    setPaymentFormError(false)
    setCardDetails((prevState: any) => ({ ...prevState, cvc: event.target.value, }))
  }




  /** Handle Billing Form end*/
  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={getSingleListingCheckoutData?.listingImages[0]?.url} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {`${getSingleListingCheckoutData?.suburb}, ${getSingleListingCheckoutData?.state}`}
              </span>
              <span className="text-base font-medium mt-1 block">
                {getSingleListingCheckoutData?.name.slice(getSingleListingCheckoutData?.name.indexOf('·') + 1)}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {`${getSingleListingCheckoutData?.bedroomsNumber} bedrooms · ${getSingleListingCheckoutData?.bedroomsNumber} baths`}
            </span>
          </div>
        </div>

        <div className=" flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          {(PriceDetails.totalNightsFee != null && PriceDetails.totalNights != null) ?
            <>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>AED {Math.abs(PriceDetails.totalNightsFee / PriceDetails.totalNights).toFixed(2)} x {Math.abs(PriceDetails.totalNights)} night</span>
                <span>AED {PriceDetails.totalNightsFee}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Cleaning Fee</span>
                <span>AED {PriceDetails.cleaningFee}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Damage Deposit</span>
                <span>AED {PriceDetails.damageDeposit}</span>
              </div>

              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>AED {PriceDetails.TotalPrice}</span>
              </div>
            </>
            :
            <>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span><Skeleton count={1} height={10} width={100} /></span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span><Skeleton count={1} height={10} width={100} /></span>
              </div>

              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span><Skeleton count={1} height={10} width={100} /></span>
              </div>
            </>}
        </div>
      </div>
    );
  };



  const renderLogin = () => {
    return <LoginSignup />
  }


  /**Handle confirm & payment - submitting to backend end */
  const renderBilling = () => {
    return <div className="_billingSection">
      <h3 className="text-2xl font-semibold">Billing Details</h3>
      <div className="mt-6">
        <form onSubmit={handlePayment}>
          <div className="w-14 border-b border-neutral-200 my-5"></div>
          <div className="space-y-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
              <div className="space-y-1">
                <Label>First Name </Label>
                <Input type="text" placeholder="your name" name="firstName" required
                  value={billingDetails.firstName}
                  onChange={(event: any) => setbillingDetails((prevState: any) => ({ ...prevState, firstName: event.target.value, }))} />
              </div>
              <div className="space-y-1">
                <Label>Last Name </Label>
                <Input type="text" placeholder="your name" name="lastName" required
                  value={billingDetails.lastName}
                  onChange={(event: any) => setbillingDetails((prevState: any) => ({ ...prevState, lastName: event.target.value, }))} />
              </div>
            </div>


            <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
              <div className="space-y-1">
                <Label>Phone {" ("}
                  <span>
                    {
                      optInvalid
                        ?
                        <span className="cursor-pointer  font-bold text-xs" onClick={generateOtp}>
                          Verify Number?
                        </span>
                        :
                        <span className=" font-semibold text-xs text-green-700">
                          Verified
                        </span>
                    }
                  </span>
                  {") "}
                </Label>
                <PhoneInput
                  country={'ae'}
                  value={billingDetails.phone}
                  onChange={(e: any, item: any) => handlePhoneChange(e, item)}
                  // onChange={(phone: any) => setbillingDetails((prevState: any) => ({ ...prevState, phone: phone, }))}
                  inputClass="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-500 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 !rounded-r-2xl text-sm font-normal !h-11 px-4 py-3 mt-1"
                  buttonClass="!rounded-l-2xl z-10 first:hover:bg-none"
                  enableSearch={true}
                />
                {phoneNumberInvalid ? <div className="text-xs text-red-600">Invalid Phone Number</div> : null}

              </div>
              <div className="space-y-1">
                <Label>Email </Label>
                <Input type="email" placeholder="example@gmail.com" name="email" required
                  value={billingDetails.email}
                  onChange={(event: any) => setbillingDetails((prevState: any) => ({ ...prevState, email: event.target.value, }))} />
              </div>
            </div>

            <div className="space-y-1">
              <Label>Street Address </Label>
              <Input type="text" placeholder="Your Address" name="streetAddress" required
                value={billingDetails.streetAddress}
                onChange={(event: any) => setbillingDetails((prevState: any) => ({ ...prevState, streetAddress: event.target.value, }))} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
              <div className="space-y-1">
                <Label>Country </Label>
                <CountryDropdown
                  name="country"
                  classes="nc-Select h-11  block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                  value={billingDetails.country}
                  valueType="short"
                  onChange={(val) => setbillingDetails((prevState: any) => ({ ...prevState, country: val, }))} />
              </div>
              <div className="sm:space-y-1">
                <Label>State </Label>
                <RegionDropdown
                  name="state"
                  classes="nc-Select h-11  block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                  country={billingDetails.country}
                  countryValueType="short"
                  value={billingDetails.state}
                  onChange={(val) => setbillingDetails((prevState: any) => ({ ...prevState, state: val, }))} />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
              <div className="sm:space-y-1">
                <Label>City </Label>
                <Input type="text" placeholder="Your City" name="city"
                  value={billingDetails.city}
                  required
                  onChange={(event: any) => setbillingDetails((prevState: any) => ({ ...prevState, city: event.target.value, }))} />
              </div>
              <div className="sm:space-y-1">
                <Label>Zip Code </Label>
                <Input type="text" placeholder="Zip/Postal Code" name="zipCode"
                  value={billingDetails.zipCode}
                  required
                  onChange={(event: any) => setbillingDetails((prevState: any) => ({ ...prevState, zipCode: event.target.value, }))} />
              </div>
            </div>


            <div className="space-y-1">
              <Label>Card Number </Label>
              <Input type='number' placeholder="xxxx xxxx xxxx xxxx" name="card-number" required
                value={cardDetails.cardNumber}
                onChange={(event: any) => handleCardNumber(event)} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-1">
              <div className="sm:space-y-1">
                <Label>Expiry Month </Label>
                <Input type="number" placeholder="11" name="expiry-month"
                  value={cardDetails.expiryMonth}
                  required
                  onChange={(event: any) => handleExpiryMonth(event)} />
                  </div>
              <div className="sm:space-y-1">
                <Label>Expiry Year </Label>
                <Input type="number" placeholder="2022" name="expiry-year"
                  value={cardDetails.expiryYear}
                  required
                  onChange={(event: any) => handleExpiryYear(event)} />
              </div>
              <div className="sm:space-y-1">
                <Label>CVC </Label>
                <Input type="number" placeholder="123" name="cvc"
                  value={cardDetails.cvc}
                  required
                  onChange={(event: any) => handleExpiryCvc(event)} />
              </div>
            </div>




            <div className="pt-4">
              <ButtonPrimary disabled={optInvalid} loading={payBtnLoading} type="submit">Confirm and pay</ButtonPrimary>
              {/* <ButtonPrimary loading={payBtnLoading} type="submit">Confirm and pay</ButtonPrimary> */}

              {paymentFormError ? <div className="pt-6 text-red-600">{paymentFormErrorMsg}</div> : null}
              {paymentFormSucess ? <div className="pt-6 text-green-600">{paymentFormSucessMsg}</div> : null}

              {/* <NcModal
              modalTitle="Booking Confirmation"
              contentExtraClass="max-w-screen-sm"
              renderTrigger={(openModal) => (
                <ButtonPrimary
                  className="w-full "
                  // disabled={optInvalid}
                  onClick={() => { openModal(); handlePayment(); }}
                  type='submit'
                >
                  Confirm
                </ButtonPrimary>
              )}
              renderContent={renderBookingPaymentModal}
            /> */}

            </div>
          </div>
        </form>
      </div>
    </div>
  }





  const renderOTPdialog = () => {
    return <Transition appear show={isOpenOTPDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto "
        onClose={closeOTPDialog}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            className="inline-block py-8 h-screen w-1/2"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-flex flex-col w-1/2 max-w-3xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                >Verification Code
                </Dialog.Title>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={() => {
                    closeOTPDialog()
                  }} />
                </span>
              </div>


              <div className="flex-grow overflow-y-auto">
                <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                  <div className="py-7 text-center flex flex-col items-center justify-center">

                    <div className="text-md font-medium leading-6 text-gray-900 dark:text-white mb-5" >
                      Please enter the verification code sent to your mobile
                    </div>

                    <OtpInput
                      onChange={(otp: any) => handleOtpInput(otp)}
                      numInputs={6}
                      value={optValue}
                      inputStyle={{
                        width: "3rem",
                        height: "3rem",
                        fontSize: "1rem",
                        borderRadius: 4,
                        border: "1px solid rgba(0,0,0,0.3)",
                        margin: '0.1rem'
                      }}
                    />
                    {
                      invalidCodeError ? <div className="text-md font-medium leading-6 text-red-600 dark:text-white mt-5" >
                        Invalid Code </div> : null
                    }
                  </div>
                </div>
              </div>

              <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-center">
                <ButtonPrimary
                  onClick={() => {
                    handleVerifyOtp()
                  }}
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  Verify
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  }





  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your trip</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
            />
          </div>

          <form className="mt-6 flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
            <StayDatesRangeInput
              wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
              onChange={(date) => setSelectedDate(date)}
              numberOfMonths={1}
              fieldClassName="p-5"
              defaultValue={selectedDate}
              anchorDirection={windowSize.width > 1400 ? "left" : "right"}
              renderDayContents={(day: any) => {
                return (
                  (moment(day).isBefore(moment())) ?
                    <div className='searchDay'
                    >{moment(day).format('D')}</div> :
                    (findBlockedDay(day)) ?
                      //if true
                      <div className='searchDay text-neutral-300'
                      >{moment(day).format('D')}<p className="text-[0.55em] text-red-600">booked</p></div> :
                      //if false
                      <div className='searchDay'
                      >{moment(day).format('D')}<p className="text-[0.55em] text-green-600">Available</p></div>
                )
              }}
              isDayBlocked={day => findBlockedDay(day)}
              minimumNights={getSingleListingCheckoutData?.minNights}
            />
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <GuestsInput
              fieldClassName="p-5"
              defaultValue={guestValue}
              max={getSingleListingCheckoutData?.personCapacity}
              onChange={(data) => {
                guestInputHandler(data)
              }}
            />
          </form>
          <span className="text-md text-red-600 p-2">{aroundBlockedDates}</span>
        </div>
        {!isLoggedIn() &&
          <div className="flex flex-col justify-center">
            <NcModal
              modalTitle="Login/SignUp"
              contentExtraClass="max-w-screen-sm"
              renderTrigger={(openModal) => (
                <ButtonPrimary
                  className="w-full"
                  onClick={() => openModal()}
                >
                  Login/SignUp to continue
                </ButtonPrimary>
              )}
              renderContent={renderLogin}
            />
          </div>}
        {renderOTPdialog()}
        {isLoggedIn() && renderBilling()}
      </div>
    );
  };






  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;