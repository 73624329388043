import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "components/SectionHero/SectionHero";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";


export interface CovidResponseProps {
  className?: string;
}

const CovidResponse: FC<CovidResponseProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-CovidResponse overflow-hidden relative ${className}`}
      data-nc-id="CovidResponse"
    >
      <Helmet>
        <title>Covid Response - Be Our Guest Dubai</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            Covid Response
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          We passionately believe the world is meant to be explored, and as vaccinations allow people to return to travel, we are ready to safely welcome them through their doors once more.
          </span>
          {<ButtonPrimary href="/contact">Contact us</ButtonPrimary>}
        </div>
        <div className="flex-grow">
          <img className="w-full rounded-xl" src="https://images.pexels.com/photos/6140944/pexels-photo-6140944.jpeg?auto=compress&cs=tinysrgb&w=728&h=750&dpr=1" alt="" />
        </div>
      </div>
      
    </div>

        <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left pt-20">
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
              Whether for business or leisure, vacation or short term rentals, we offer a variety of carefully, handpicked apartments and villas in selected prime locations ensuring that your experience with us is truly a memorable one. Our team will also be more than happy to help you enjoy what the city has to offer by organizing the best encounters and attractions suited to your personal interests. 
            </span>
        </div>

        
      </div>
    </div>
  );
};

export default CovidResponse;
