// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
// import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
// import React, { FC, useContext, useEffect, useState } from "react";
// import SectionGridFilterCard from "./SectionGridFilterCard";
// import { Helmet } from "react-helmet";
// import axios from "axios";
// import { useQuery } from "react-query";
// import FilterContext from "context/FilterContext";
// import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import moment from "moment";


// export interface ListingStayPageProps {
//   className?: string;
// }

// const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
//   const navigate = useNavigate();
//   const { location } = useParams<string>();
//   const [searchParams] = useSearchParams();
//   const startDate = searchParams.get('sd');
//   const endDate = searchParams.get('ed');
//   const adults = searchParams.get('adults');

//   const { listings, getListingsData, getListingsLoading, getListingsisSuccess, updateSearchFilter, apiErrorResponse, setapiErrorResponse } = useContext(FilterContext);


//   // console.log({ listings })
//   /**below UseEffect will clear the sub filter as enters the page & check for locations & update if any into global state*/
//   useEffect(() => {
//     updateSearchFilter('', 'clearFilters');
//   }, []);

//   useEffect(() => {
//     updateSearchFilter('', 'clearFilters');
//     if (location != null) {
//       updateSearchFilter(location, 'location');
//     }
//     if (startDate != null) {
//       updateSearchFilter(moment(startDate), "start_date");
//     }
//     if (endDate != null) {
//       updateSearchFilter(moment(endDate), "end_date");
//     }
//     if (adults != null) {
//       updateSearchFilter(Number(adults), "guests_count");
//     }
//   }, [location, startDate, endDate, adults]);


//   useEffect(() => {
//     if (apiErrorResponse === 400) {
//       navigate("/404", { replace: true });
//     }
//     return () => {
//       setapiErrorResponse("")
//     }
//   }, [apiErrorResponse])



//   getListingsisSuccess && console.log("show response of RQ", getListingsData);

//   return (
//     <div
//       className={`nc-ListingStayPage relative overflow-hidden ${className}`}
//       data-nc-id="ListingStayPage"
//     >
//       <Helmet>
//         <title>Book your stay - Search Properties</title>
//       </Helmet>
//       <BgGlassmorphism />

//       <div className="container relative overflow-hidden">
//         {/* SECTION HERO */}
//         <SectionHeroArchivePage
//           currentPage="Stays"
//           currentTab="Stays"
//           className="pt-10 pb-8 lg:pb-8 lg:pt-16 "
//         />

//         {/* SECTION */}
//         <SectionGridFilterCard data={listings} isLoading={getListingsLoading} className="pb-24 lg:pb-32" />
//       </div>
//     </div>
//   );
// };

// export default ListingStayPage;









//test
import React, { FC, useContext, useEffect, useState } from "react";
import moment from "moment";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = () => {
  useEffect(() => {

    const testT = moment().toDate()
    const testToday = moment(testT, 'YYYY-MM-DD[T]HH:mm:ss').format("MM/DD/YYYY")

    const testTom = moment().add(1, 'days');
    const testTomorrow = moment(testTom, 'YYYY-MM-DD[T]HH:mm:ss').format("MM/DD/YYYY")

    window.open(`https://bookings11.rmscloud.com/Rates/Index/17186/59?A=${testToday}&D=${testTomorrow}&Dn=20220826115553&Ad=2&C=0&I=0&Ps=17186&cattrIds=#!/rooms`)
  }, [])

  return null;
};

export default ListingStayPage;