import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useContext, useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionGridFeatureProperty from "./SectionGridFeatureProperty";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { ListingCardDataType, TaxonomyType, VideoType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from "react-query";
import LocationsSlider from "components/LocationsSlider/Locationslider";
import FilterContext from "context/FilterContext";


function PageHome() {


  const { data: homePage, isSuccess: homePageIsSuccess } = useQuery(['fetchHomePage'], async () => {
    const response = await axios.get('https://objects.bogportal.com/wp-json/wp/v2/pages/?slug=home');
    return response.data[0];
  }, { staleTime: 900000, });

  const { updateSearchFilter } = useContext(FilterContext);

  useEffect(() => {
    updateSearchFilter("", "clearAllFilters");
  }, [])
  console.log("homepage", homePage);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Be Our guest - Holiday Homes Dubai</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-20 pb-16"
          heading={homePage?.acf?.heading}
          subheading={homePage?.acf?.subheading}
        />

        {/* Featured Property - SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeatureProperty />
        </div> */}

        {/* Areas - SECTION */}
        <LocationsSlider
          heading="Explore By Areas"
          subHeading="Check stays by top locations"
          className="Locations"
          itemClassName="LocationsCardItem"
          itemPerRow={5}
          sliderStyle="style1"
          uniqueClassName="AreaCardsSection"
        />

        {/* Property Types - SECTION 
        <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Choose your stay"
          className = ""
          itemClassName = ""
          categories = {PROPERTY_TYPES}
          itemPerRow = {5}
          categoryCardType = "card5"
          sliderStyle = "style1"
          uniqueClassName="PropertyTypeCardSection" />*/}

        {/* Videos - SECTION */}
        {/* <SectionVideos videos={homePage?.acf?.videos?.video} /> */}

        {/* Testimonials - SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>

      {/* SECTION- Our features- <SectionOurFeatures /> */}


      {/* SECTION - grid box property card
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>
        */}

      {/* SECTION <SectionHowItWork />*/}
      {/* SECTION <SectionSubscribe2 />*/}


      {/* SECTION - show authors
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>
        */}
      {/* SECTION <SectionGridCategoryBox />*/}


      {/* SECTION 
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>*/}

    </div>
  );
}


export default PageHome;

