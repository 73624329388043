import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import NcImage from "shared/NcImage/NcImage";
import Heading from "shared/Heading/Heading";

export interface OTHER_REAL_ESTATE_SERVICES {
    id: string;
    title: string;
    desc: string;
    image: string;
  }
  
  const OTHER_REAL_ESTATE_SERVICES: OTHER_REAL_ESTATE_SERVICES[] = [
    {
      id: "1",
      title: `D&B Properties`,
      desc: "Ready Property Sales · Residential Leasing · Off-Plan Property Investment",
      image:
        "https://images.unsplash.com/photo-1470376619031-a6791e534bf0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
      id: "4",
      title: `Real Estate Commercial Experts (RECE)`,
      desc: "Commercial Sales & Leasing",
      image:
        "https://images.unsplash.com/photo-1585692614093-62dab82e9d08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
      id: "3",
      title: `Valuation & Advisory Services (VAS)`,
      desc: "Valuation, Advisory, and Research of your property or business assets",
      image:
        "https://images.unsplash.com/photo-1613280194169-6bb2f32a6bfa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
  ];

export interface REAL_SCOPE_OF_SERVICES {
      title: string;
}
const REAL_SCOPE_OF_SERVICES: REAL_SCOPE_OF_SERVICES[]= [
    {title:"Property Marketing and Signage Advertising for Lettings"},
    {title:"Tenant Screening and Document Collection"},
    {title:"Welcome Email and Briefing"},
    {title:"Ejari Registration"},
    {title:"Move-In and Move-Out Assistance"},
    {title:"Rent and Security Deposit Collection"},
    {title:"Rent Management (Banking of Cheques)"},
    {title:"Full Year Maintenance and Financial Report"},
    {title:"Tenancy Renewal and Full Execution Management"},
    {title:"Community Service Charges and Utility Billing Maintenance"},
    {title:"Bi-Yearly Property Routine Inspection Report"},
    {title:"Rental Dispute Case Assistance"},

]

export interface PropertyManagementProps {
  className?: string;
}

const PropertyManagement: FC<PropertyManagementProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PropertyManagement overflow-hidden relative ${className}`}
      data-nc-id="PropertyManagement"
    >
      <Helmet>
        <title>Property Management - Be Our Guest Dubai</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div
        className={`nc-SectionHero relative`}
        data-nc-id="SectionHero"
        >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
          🏘️ Property Management
          </h1>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          Your property is an asset, and you need to ensure that its value remains high in the market and consistently yielding income. Managing this balance and helping you reap the benefits can be maximized through professional help.          </span>
          {/* {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>} */}
        </div>
        <div className="flex-grow ml-4">
          <img className="w-full rounded-xl" src="https://images.pexels.com/photos/415687/pexels-photo-415687.jpeg?auto=compress&cs=tinysrgb&w=800" alt="Property Management" />
        </div>
      </div>
    </div>
    <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left pt-4">
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
            Our sister division, Real Estate Asset Leaders (REAL), which is also an integrated division under D&B Properties, is expert in property and asset management.
            </span>
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
            From a systematic upkeep of your property to professional assistance in resolving concerns in your property, our Senior Property Managers will ensure that you can sit back and be at ease while we look after your investments and help you earn hassle-free rental income wherever you are.
            </span>
    </div>
    <div>
        <div className="nc-Section relative">
            <h2 className="font-semibold text-4xl mt-5">REAL Scope of Services</h2>
            <div className="grid grid-flow-row-dense grid-cols-1 grid-rows-3 mt-12 sm:grid-cols-3">
            { REAL_SCOPE_OF_SERVICES.map((item, index) => (
                <div key={index} className="my-4 mr-6 bg-slate-100 p-4 rounded-xl dark:bg-neutral-800">
                    <span className="text-base">{item.title}</span>
                </div>
            ))}
            </div>
        </div>
        <div className="nc-SectionFounder relative my-32">
            <Heading
                desc="A selection of pristine, expertly designed homes with high-end amenities and services"
            >
                Other Real Estate Services We Offer
            </Heading>
            <div className="grid sm:grid-cols-1 gap-x-5 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                {OTHER_REAL_ESTATE_SERVICES.map((item) => (
                <div key={item.id} className="max-w-sm">
                    <NcImage
                    containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                    className="absolute inset-0 object-cover"
                    src={item.image}
                    />
                    <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                    {item.title}
                    </h3>
                    <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                    {item.desc}
                    </span>
                </div>
                ))}
            </div>
        </div>
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PropertyManagement_" />
        </div>

        {/* <SectionStatistic /> */}

        {/*<SectionSubscribe2 />*/}
      </div>
    </div>
    </div>
  );
};

export default PropertyManagement;
