import React from "react";
import ReactDOM from "react-dom";
//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import { FilterProvider } from "./context/FilterContext";
import { UserProvider } from "./context/UserContext";
import { BookingProvider } from "./context/BookingContext";

const queryClient = new QueryClient();
ReactDOM.render(
    <QueryClientProvider client={queryClient}>
            <UserProvider>
            <FilterProvider>
            <BookingProvider>
                <App />
            </BookingProvider>
            </FilterProvider>
            </UserProvider>
            
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
, document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
