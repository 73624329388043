import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { PROPERTY_LISTINGS } from "data/listings";
import { ListingCardArray, ListingCardDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import PropertyCardH from "components/PropertyCardH/PropertyCardH";
import axios from "axios";
import { useQuery } from "react-query";
import FilterContext from "context/FilterContext";
import { Link } from "react-router-dom";


// OTHER DEMO WILL PASS PROPS
const STATIC_PROEPRTY_LISTING_DATA: ListingCardDataType = PROPERTY_LISTINGS;

//
export interface SectionGridFeaturePropertyProps {
  propertyListingCard?: ListingCardDataType;
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  isLoading?: boolean;
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({


  propertyListingCard = STATIC_PROEPRTY_LISTING_DATA,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that BeOurGuest recommends for you",
  headingIsCenter,
  tabs = ["Dubai Marina", "Downtown Dubai", "Jumeirah Village Circle", "Dubai Media City"],
}) => {
  const [onClickTabLocationValue, setonClickTabLocationValue] = useState("");
  const { getListingsData, getListingsLoading, getListingsisSuccess, updateSearchFilter, getLocationsData, getLocationsisSuccess } = useContext(FilterContext);

  /**clear the filter as enters the page */
  useEffect(() => {
    updateSearchFilter('', 'clearFilters');
  }, []);


  const renderCard = (propertyListingCard: ListingCardArray, index: number) => {
    return <PropertyCardH key={index} className="h-full" data={propertyListingCard} />;
  };
  console.log({ getLocationsData });
  return (
    <div className="nc-SectionGridFeatureProperty relative">
      <HeaderFilter
        tabActive={""}
        subHeading={subHeading}
        tabs={getLocationsData}
        isTabsLoadingSuccess={getListingsisSuccess}
        heading={heading}
        onClickTab={(e) => {
          setonClickTabLocationValue(e);
          updateSearchFilter(e, 'location')
        }}
      />
      <div
        className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}
      >
        {
          (getListingsLoading) ?
            STATIC_PROEPRTY_LISTING_DATA?.response?.filter((_: any, idx: number) => idx < 8).map(renderCard)
            : getListingsData?.response?.filter((_: any, idx: number) => idx < 8).map(renderCard)
        }
      </div>
      <div className="flex mt-16 justify-center items-center">
        <Link to={`/short-term-rentals/dubai/${onClickTabLocationValue}`} ><ButtonPrimary >Show me more</ButtonPrimary></Link>
      </div>
    </div>
  );
};

export default SectionGridFeatureProperty;
