import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { LocationsDataType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";

export interface LocationCardProps {
  className?: string;
  location: LocationsDataType;
}

const LocationCard: FC<LocationCardProps> = ({
  className = "",
  location,
}) => {
  const { featured, image, name, nameSlug } = location;
  return (
    // <Link
    //   to={"/short-term-rentals/dubai/"+nameSlug}
    //   className={`nc-LocationCard flex flex-col ${className}`}
    //   data-nc-id="LocationCard"
    // >
    //   <div
    //     className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
    //   >
    //     <NcImage
    //       src={image ? image : 'https://images.pexels.com/photos/2041556/pexels-photo-2041556.jpeg?auto=compress&cs=tinysrgb&w=250&h=350&dpr=1'}
    //       className="object-cover w-full h-full rounded-2xl"
    //     />
    //     <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
    //   </div>
    //   <div className="mt-4 truncate">
    //     <h2
    //       className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
    //     >
    //       {name}
    //     </h2>
    //     {/*<span
    //       className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
    //     >
    //       {convertNumbThousand(count || 0)} properties
    //     </span>*/}
    //   </div>
    // </Link>


    <div
      className={`nc-LocationCard flex flex-col ${className}`}
      data-nc-id="LocationCard"
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={image ? image : 'https://images.pexels.com/photos/2041556/pexels-photo-2041556.jpeg?auto=compress&cs=tinysrgb&w=250&h=350&dpr=1'}
          className="object-cover w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 truncate">
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {name}
        </h2>
        {/*<span
          className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
        >
          {convertNumbThousand(count || 0)} properties
        </span>*/}
      </div>
    </div>
  );
};

export default LocationCard;
