import Label from "components/Label/Label";
import React, { FC, useContext, useLayoutEffect, useRef, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import UserContext from "context/UserContext";
import moment from "moment";
import { axiosInstance } from "auth/api";
import { Store } from 'react-notifications-component';
import PhoneInput from "react-phone-input-2";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";


export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const  { getUserData, getUserisSuccess }  = useContext(UserContext);
  const [AccountFormData, updateAccountFormData] = useState({
    mobile: "",
    state: "",
    countryCode: "",
})
useLayoutEffect(() => {
  if(getUserisSuccess){
    if(AccountFormData.mobile=="" && getUserData?.phone!=""){
      updateAccountFormData((prevState: any) => ({...prevState, mobile: getUserData?.mobile,}))
    }
    if(AccountFormData?.countryCode=="" && getUserData?.countryCode!=""){
      updateAccountFormData((prevState: any) => ({...prevState, countryCode: getUserData?.countryCode,}))
    }
    if(AccountFormData?.state=="" && getUserData?.state!=""){
      updateAccountFormData((prevState: any) => ({...prevState, state: getUserData?.state,}))
    }
  }
  // return () => {
  //   second
  // };
}, [getUserData])
console.log({AccountFormData})
const AccountFormDataRef = useRef<HTMLFormElement | null>(null);

//  updateAccountFormData({
      //     ...AccountFormData,
      //         email: AccountFormDataRef?.current?.email?.value,
      //         mobile: AccountFormDataRef?.current?.mobile?.value,
      //         firstName: AccountFormDataRef?.current?.firstName?.value,
      //         lastName:AccountFormDataRef?.current?.lastName?.value,
      //         dob:AccountFormDataRef?.current?.dob?.value,
      //         address: AccountFormDataRef?.current?.address?.value,
      //         state: AccountFormDataRef?.current?.state?.value,
      //         city: AccountFormDataRef?.current?.city?.value,
      //         countryCode: AccountFormDataRef?.current?.countryCode?.value,
      //         zipcode: AccountFormDataRef?.current?.zipcode?.value,
      // });

const handleAccountFormDataChange = (event: { target: { name: any; value: any; }; }) => {
  updateAccountFormData({
    ...AccountFormData,
    [event.target.name]: event.target.value
  });
}


const handleAccountFormSubmit  = (e:any)  => {
    e.preventDefault();
    const tempFormData = {
            "email": AccountFormDataRef?.current?.email?.value,
            "mobile": AccountFormDataRef?.current?.mobile?.value,
            "firstName": AccountFormDataRef?.current?.firstName?.value,
            "lastName":AccountFormDataRef?.current?.lastName?.value,
            "address": AccountFormDataRef?.current?.address?.value,
            "city": AccountFormDataRef?.current?.city?.value,
            "zipcode": AccountFormDataRef?.current?.zipcode?.value,
            "countryCode": AccountFormData?.countryCode,
            "state": AccountFormData?.state,
            ...(((AccountFormDataRef?.current?.dob?.value!="") && {dob: AccountFormDataRef?.current?.dob?.value,})),
    }

  updateAccountDetailsApiCall(tempFormData).then((item:any)=>{
    (item?.status===200)?
    Store.addNotification({
      title: "Succuess",
      message: item.message,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    }):Store.addNotification({
      title: "Error",
      message: item.message,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }
    )
}

const updateAccountDetailsApiCall =async (AccountUpdateForm: any) => {
  console.log({AccountUpdateForm})
  try {
    // make axios post request
    const response = await axiosInstance({
      method: "post",
      url: "/update_info",
      data: AccountUpdateForm,
    });
    console.log({response})
    if(response?.status===200){
      return {
        "status":response?.status,
        "message": response?.data?.message,
        };
    }
  } catch(error) {
    console.log()
    return {
      "status": error?.response?.status,
      "message": "There's some problem, try again after sometime.",
      };
    
  }
}

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Profile - Be Our Guest</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar 
                sizeClass="w-32 h-32" 
                imgUrl={`https://ui-avatars.com/api/?background=34a9b1&rounded=true&name=${getUserData?.firstName} ${getUserData?.lastName}`}
                />
                {/* <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div> 
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />*/}
              </div>
            </div>
            {<form  onSubmit={handleAccountFormSubmit} ref={AccountFormDataRef} className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <div>
                  <Label>First Name</Label>
                  <Input className="mt-1.5" name="firstName" type="text" defaultValue={(getUserData?.firstName!=null)?getUserData?.firstName:''}/>
                </div>
                <div>
                  <Label>Last Name</Label>
                  <Input className="mt-1.5" type="text" name="lastName" defaultValue={(getUserData?.lastName!=null)?getUserData?.lastName:''}/>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <div>
                  <Label>Email</Label>
                  <Input className="mt-1.5 bg-neutral-200 dark:bg-neutral-700" name="email" type="email" disabled defaultValue={(getUserData?.email!=null)?getUserData?.email:''} />
                </div>
                <div>
                  <Label>Phone</Label>
                  <PhoneInput
                    inputProps={{
                      name: 'mobile',
                    }}
                    country={'ae'}
                    value={AccountFormData.mobile}
                    onChange={(mobile: any) => updateAccountFormData((prevState: any) => ({...prevState, mobile: mobile,}))}
                    inputClass ="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-500 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 !rounded-r-2xl text-sm font-normal !h-11 px-4 py-3 mt-1"
                    buttonClass="!rounded-l-2xl z-10 first:hover:bg-none"
                    enableSearch={true}
                  />
                  {/* <Input className="hidden" name="mobile" type="text" defaultValue={(getUserData?.mobile!=null)?getUserData?.mobile:''} /> */}
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <div>
                  <Label>Address</Label>
                  <Input className="mt-1.5" type="text" name="address"  defaultValue={(getUserData?.address!=null)?getUserData?.address:''} />
                </div>
                <div>
                  <Label>City</Label>
                  <Input className="mt-1.5" type="text" name="city" defaultValue={(getUserData?.city!=null)?getUserData?.city:''} />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <div>
                  <Label>country</Label>
                  <CountryDropdown
                    name="countryCode"
                    classes="nc-Select h-11  block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                    value={AccountFormData.countryCode}
                    valueType="short"
                    onChange={(val) =>updateAccountFormData((prevState: any) => ({...prevState, countryCode: val,}))} />
                  {/* <Input className="mt-1.5" type="text" name="countryCode"  defaultValue={(getUserData?.countryCode!=null)?getUserData?.countryCode:''} /> */}
                </div>
                <div>
                  <Label>state</Label>
                  <RegionDropdown
                    name="state"
                    classes="nc-Select h-11  block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                    country={AccountFormData.countryCode}
                    countryValueType="short"
                    value={AccountFormData.state}
                    onChange={(val) => updateAccountFormData((prevState: any) => ({...prevState, state: val,}))} />
                  {/* <Input className="mt-1.5" type="text" name="state" defaultValue={(getUserData?.state!=null)?getUserData?.state:''} /> */}
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <div>
                  <Label>ZipCode</Label>
                  <Input className="mt-1.5" type="text" name="zipcode"  defaultValue={(getUserData?.zipcode!=null)?getUserData?.zipcode:''} />
                </div>
                <div>
                  <Label>Date of birth</Label>
                  <Input
                    className="mt-1.5"
                    type="date"
                    defaultValue={(getUserData?.dob!=null)?getUserData?.dob:''}
                    name="dob"
                  />
                </div>
              </div>
              {/* ---- */}
              {/* <div>
                <Label>Gender</Label>
                <Select className="mt-1.5">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </div> */}

              <div>
                <span>Account Created on: {moment(getUserData?.createdAt).format("YYYY-m-DD")}</span>
              </div>
              <div className="pt-2">
                <ButtonPrimary type="submit">Update info</ButtonPrimary>
              </div>
            </form>}
          </div>
          </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
