import { PostDataType } from "data/types";
import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import Badge from "shared/Badge/Badge";

export interface CategoryBadgeListProps {
  className?: string;
  itemClass?: string;
  categories: any[];
  //categories: PostDataType["categories"];
}

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  categories,
}) => {
  return (
    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      {(categories)?
          categories?.map((item, index) => (
            item?.name &&
            <Badge
              className={itemClass}
              key={index}
              name={item?.name}
              href={`/blog/${item?.slug}`}
              color={item.color as any}
            />
          )):
          <Skeleton count={1} height={15} width={50} />
      }
      {}
    </div>
  );
};

export default CategoryBadgeList;
