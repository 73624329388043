import React, { ReactNode, useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import CovidResponse from "containers/CovidResponse/CovidResponse";
import PrivacyPolicy from "containers/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "containers/TermsAndConditions/TermsAndConditions";
import UserContext from "context/UserContext";
import Bookings from "containers/AccountPage/Reservations";
import { isLoggedIn } from "axios-jwt";
import ListYourProperty from "containers/PropertyOwners/ListYourProperty";
import PropertyManagement from "containers/PropertyOwners/PropertyManagement";
import InteriorDesign from "containers/PropertyOwners/InteriorDesign";
import OwnersFaq from "containers/PropertyOwners/OwnersFaq";
import ProtectedRoutes from "components/ProtectedRoute/ProtectedRoutes";
/*
export const pages: Page[] = [
  { path: "/", exact: true, component:  PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  //
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  //
{
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  //{ path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //

  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  //static pages
  { path: "/covid-response", component: CovidResponse },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  //
];*/

interface BOGRoutesProps {
  children: ReactNode;
}

function BOGRoutes({ children }: BOGRoutesProps) {
  return (
    <>
      <BrowserRouter basename="/">
        <ScrollToTop />
        <SiteHeader />

        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route path="/#" element={<PageHome />} />
          <Route path="/home-2" element={<PageHome2 />} />

          <Route path="/short-term-rentals/dubai" element={<ListingStayPage />} >
            <Route path="/short-term-rentals/dubai/:location" element={<ValidateLocation />} />
          </Route>


          <Route path="/listing-stay-map" element={<ListingStayMapPage />} />
          <Route path="/listing/:slug" element={<ListingStayDetailPage />} />

          <Route path="/checkout" element={<CheckOutPage />} />
          <Route path="/pay-done" element={<PayPage />} />

          {/* <Route path="/profile" element={(isLoggedIn())?<AccountPage />:<PageLogin />} />
        <Route path="/profile/wishlist" element={(isLoggedIn())?<AccountSavelists />:<PageLogin />}  />
        <Route path="/profile/bookings" element={(isLoggedIn())?<Bookings />:<PageLogin />} /> */}

          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/profile" element={<AccountPage />} />
            <Route path="/profile/wishlist" element={<AccountSavelists />} />
            <Route path="/profile/bookings" element={<Bookings />} />
          </Route>

          {/* <Route path="blog" element={<BlogPage />}>
          <Route path=":category/" element={<BlogPage />} >
            <Route path=":post" element={<BlogSingle />} />
          </Route>
        </Route> */}
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:category" element={<BlogPage />} />
          <Route path="blog/:category/:post" element={<BlogSingle />} />

          <Route path="/contact" element={<PageContact />} />
          <Route path="/about" element={<PageAbout />} />
          <Route path="/signup" element={(isLoggedIn()) ? <PageHome /> : <PageSignUp />} />
          <Route path="/login" element={(isLoggedIn()) ? <PageHome /> : <PageLogin />} />
          <Route path="/subscription" element={<PageSubcription />} />

          {/**Static Data Pages*/}
          <Route path="/covid-response" element={<CovidResponse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path='/404' element={<Page404 />} />
          <Route path='*' element={<Navigate replace to="/404" />} />

          {/**Property Owners*/}
          <Route path="/list-property" element={<ListYourProperty />} />
          <Route path="/property-management" element={<PropertyManagement />} />
          <Route path="/interior-design" element={<InteriorDesign />} />
          <Route path="/owners-faq" element={<OwnersFaq />} />

          {/*Manual Redirects in routes*/}
          <Route path="/listing" element={<Navigate to="/listings" replace />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

function ValidateLocation() {
  let { location } = useParams<string>();
  if (location == null) {
    return <ListingStayPage />;
  }
  else {
    let locationParam = location.match(/^[A-Za-z0-9-]+$/);
    if (true) {
      return <ListingStayPage />;
    } else {
      return <Page404 />;
    }
  }
}

export default BOGRoutes;
