import React, { useContext, useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import moment from "moment";
import { FC } from "react";
import FilterContext from "context/FilterContext";
import Fuse from "fuse.js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "";
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, "days"),
};
const defaultGuestValue: GuestsInputProps["defaultValue"] = {
  guestAdults: 2,
  guestChildren: 2,
  // guestInfants: 1,
};

const StaySearchForm: FC<StaySearchFormProps> = ({
  haveDefaultValue = false,
}) => {

  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
  const [locationInputValue, setLocationInputValue] = useState("");
  const [testLocationId, setTestLocationId] = useState("");

  const [guestValue, setGuestValue] = useState<GuestsInputProps["defaultValue"]>({ guestAdults: 2, guestChildren: 0 });

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );
  /**search url params */
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get('sd');
  const endDate = searchParams.get('ed');
  const adults = searchParams.get('adults');
  const children = searchParams.get('children');
  const { location } = useParams<string>();
  const navigate = useNavigate();
  /**search url params end */
  /**temp variables to pass search values */
  let locationslug: string = '';
  const locationArray: any = [];


  const { getLocationsData, getLocationsisSuccess, updateSearchFilter, FilterData } = useContext(FilterContext);

  //test
  useEffect(() => {
    getLocationsData?.forEach((element: any) => {
      if (locationInputValue == element.name) {
        setTestLocationId(element.id);
      }
    });
  }, [locationInputValue])

  /**Check global filter state and update the values in state. */
  useEffect(() => {
    if (startDate != null && dateIsValid(startDate) && endDate != null && dateIsValid(endDate)) {
      setDateRangeValue({
        startDate: moment(startDate),
        endDate: moment(endDate),
      })
    }
    if (location != '') {
      getLocationsData?.forEach((element: any) => {
        if (location == element.nameSlug) {
          // console.log(element)
          setLocationInputValue(element.name)
        }
      });
      updateSearchFilter(location, 'location');
    }
    if (adults != null) {
      setGuestValue((prevState) => ({ ...prevState, guestAdults: Number(adults), }))
      setGuestValue((prevState) => ({ ...prevState, guestChildren: Number(children), }))
    }
    if (children != null) {
      setGuestValue((prevState) => ({ ...prevState, guestChildren: Number(children), }))
    }
  }, [getLocationsData])


  const [storeLocationsData, setstoreLocatiosData] = useState<any[]>([getLocationsData]);
  const AutoCompleteLocation = new Fuse(getLocationsisSuccess && getLocationsData, {
    keys: ['name'],
    useExtendedSearch: true,
    threshold: 0.2,
  });

  const handleAutoComplete = (e: React.SetStateAction<string>) => {
    setLocationInputValue(e);


    const results = AutoCompleteLocation.search(`"${locationInputValue}"`);
    (results.length >= 1) ?
      setstoreLocatiosData([...results]) :
      setstoreLocatiosData([{
        "item": {
          "count": 0,
          "featured": false,
          "id": 99,
          "image": null,
          "name": "No Results Found"
        }
      }]);
  }

  const handleSearchFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await getNavigateUrl();
    updateSearchFilter('', 'clearAllFilters');
    let modifiedNavigationUrl = '?' + locationArray.join('').substring(1);
    // navigate(`/short-term-rentals/dubai/${locationslug}${modifiedNavigationUrl}`);


    //test
    const testT = moment().toDate()
    const testToday = moment(testT, 'YYYY-MM-DD[T]HH:mm:ss').format("MM/DD/YYYY")

    const testTom = moment().add(1, 'days');
    const testTomorrow = moment(testTom, 'YYYY-MM-DD[T]HH:mm:ss').format("MM/DD/YYYY")

    const testArrivalDate = moment(dateRangeValue?.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format("MM/DD/YYYY");
    const testDepartureDate = moment(dateRangeValue?.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format("MM/DD/YYYY");
    const testTotalGuest = guestValue.guestAdults;

    if (!dateRangeValue?.startDate && !dateRangeValue?.endDate) {
      window.open(`https://bookings11.rmscloud.com/Rates/Index/17186/59?A=${testToday}&D=${testTomorrow}&Dn=20220826115553&Ad=${testTotalGuest}&C=0&I=0&Ps=17186&cattrIds=${testLocationId}#!/rooms`)
    } else {
      window.open(`https://bookings11.rmscloud.com/Rates/Index/17186/59?A=${testArrivalDate}&D=${testDepartureDate}&Dn=20220826115553&Ad=${testTotalGuest}&C=0&I=0&Ps=17186&cattrIds=${testLocationId}#!/rooms`)
    }
  }

  /**get url variables filled in */
  const getNavigateUrl = async () => {
    await getLocationsData?.forEach((element: any) => {
      if (locationInputValue == element.name) {
        locationslug = element.nameSlug;
      }
    });
    if (dateRangeValue?.startDate != null && dateIsValid(moment(dateRangeValue?.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"))) {
      locationArray.push(`&sd=${moment(dateRangeValue?.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")}`);
    }
    if (dateRangeValue?.endDate != null && dateIsValid(moment(dateRangeValue?.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"))) {
      locationArray.push(`&ed=${moment(dateRangeValue?.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")}`);
    }
    if (Number(guestValue['guestAdults']) != null) {
      locationArray.push(`&adults=${Number(guestValue['guestAdults'])}`);
    }
    if (Number(guestValue['guestChildren']) != null) {
      locationArray.push(`&children=${Number(guestValue['guestChildren'])}`);
    }
  }

  const renderForm = () => {
    return (
      <form
        onSubmit={handleSearchFormSubmit}
        className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0">
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => handleAutoComplete(e)}
          onInputDone={() => setDateFocused("startDate")}
          locationsData={getLocationsData}
          autocompleteLocationsData={(storeLocationsData.length) ? storeLocationsData : getLocationsData}
        />
        <StayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onChange={(data) => { setDateRangeValue(data); }}
        />
        <GuestsInput
          defaultValue={guestValue}
          onChange={(data) => { setGuestValue(data) }}
        />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0 flex items-center justify-center">
          {/* <ButtonSubmit 
          /> */}
          <ButtonPrimary
            type={"submit"}
            className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
            sizeClass="px-4 py-2 sm:px-5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </ButtonPrimary>
        </div>
      </form>
    );
  };
  return renderForm();
};

function dateIsValid(dateStr: any) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (dateStr.match(regex) === null) {
    return false;
  }
  const date = new Date(dateStr);
  const timestamp = date.getTime();
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }
  return date.toISOString().startsWith(dateStr);
}

export default StaySearchForm;
