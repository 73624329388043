import React, { FC, Fragment, useContext, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "context/UserContext";
import { Transition, Dialog } from "@headlessui/react";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Button from "shared/Button/Button";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "https://frontend-connect.bogportal.com/google/login",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const [isOpenSignupModal, setisOpenSignupModal] = useState(false);
  const closeSignupModal = () => setisOpenSignupModal(false);
  const openSignupModal = () => setisOpenSignupModal(true);
  const [isloading, setisloading] = useState(false)

  const [signupResponse, setsignupResponse] = useState<any>({
    status: '',
    message: '',
  })
  const [firstName, setfirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const [confirmPassword, setconfirmPassword] = useState(null);
  const [errors, seterrors] = useState({
    firstName: '',
    lastName:'',
    email: '',
    password: '',
    confirmpassword: '',
  })
  const navigate = useNavigate();
  const  { signupUser }  = useContext(UserContext);
  const getsignupResponse =()=>{
    return signupResponse.message;
  }
  const handleChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errorstemp = errors;

    switch (name) {
      case 'firstName': 
      errorstemp.firstName = 
          value.length < 1
            ? 'Name must be at least 1 characters long!'
            : '';
            setfirstName(value);
            seterrors((prevState) => ({...prevState, [name]: errorstemp.firstName,}));
        break;
      case 'lastName': 
      errorstemp.lastName = 
          value.length < 1
            ? 'Name must be at least 1 characters long!'
            : '';
            setlastName(value);
            seterrors((prevState) => ({...prevState, [name]: errorstemp.lastName,}));
        break;
      case 'email': 
      errorstemp.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
            setemail(value);
            seterrors((prevState) => ({...prevState, [name]: errorstemp.email,}));
        break;
      case 'password':
      errorstemp.password = 
          value.length < 8
            ? 'Password must be at least 8 characters long!'
            : '';
            setpassword(value);
            seterrors((prevState) => ({...prevState, [name]: errorstemp.password,}));
        break;
      case 'confirmpassword':
      errorstemp.confirmpassword = 
          (value !== password)
            ? 'Password did not match'
            : '';
            setconfirmPassword(value);
            seterrors((prevState) => ({...prevState, [name]: errorstemp.confirmpassword,}));
        break;
      default:
        break;
    }
  }

  const handleSignup = (e:any)=>{
    e.preventDefault();
    if(validateForm(errors)) {
      setisloading(true);
      signupUser(firstName,lastName,email,password,confirmPassword).then((res: any)=>{
        setsignupResponse((prevState: any) => ({...prevState, status: res.status,message:res.message,}));
        setisloading(false);
        openSignupModal();
        if(res.status===200){
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 3000);
        }
      });
    }else{
      console.error('Invalid Form');
    }
  }
  
  const renderSignupModal = () => {
    return (
      <div>
        <Transition appear show={isOpenSignupModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeSignupModal}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-1/2"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-1/2 max-w-3xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      {(signupResponse.status===200)?
                      "👏 Congratulations, you've signedup"
                      :(signupResponse.status===400)?
                      "🤨"
                      :(signupResponse.status===500)?
                      "☹️ Signup Failed"
                      :"🙄"
                      }
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={()=>{
                        closeSignupModal()}} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7 text-center">
                        <h3 className="text-xl font-base text-center">{signupResponse.message}</h3>
                        {/* {signupResponse.status===200 &&
                        <Link to={"/login"} ><Button sizeClass="px-4 py-2 sm:px-5" >Click here to login</Button> </Link>
                        } */}
                      </div>
                      
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={()=>{
                        closeSignupModal()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Close
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up | Be Our Guest | Dubai</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-secondary-300 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSignup} action="#" method="post" >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="mt-1"
                onChange={handleChange}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="mt-1"
                onChange={handleChange}
              />
            </label>
            </div>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={handleChange}
              />
            </label>
            <label className="block">
              <span className="loading flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" name="password" className="mt-1" onChange={handleChange}/>
              {errors.password.length > 1 && 
                <span className='error'>{errors.password}</span>}
            </label>
            <label className="block">
              <span className="loading flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password" name="confirmpassword" className="mt-1" onChange={handleChange}/>
              {errors.confirmpassword.length > 1 && 
                <span className='error'>{errors.confirmpassword}</span>}
            </label>
            <ButtonPrimary loading={isloading} type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
      {renderSignupModal()}
    </div>
    
  );
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors: { firstName: string, lastName: string, email:string,password:string,confirmpassword:string }) => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};


export default PageSignUp;
