import React, { FC, useContext, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { LocationsDataType } from "data/types";
import NextPrev from "shared/NextPrev/NextPrev";
import LocationCard from "./LocationCard";
import axios from "axios";
import { useQuery } from "react-query";
import FilterContext from "context/FilterContext";

export interface LocationsSliderProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  locations?: LocationsDataType[];
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const PROPERTY_TYPES: LocationsDataType[] = [
  {
    id: 1234561,
    featured: false,
    image: null,
    name: "Al Barsha",
    nameSlug: "al-barsha"
  },
  {
    id: 1234561,
    featured: false,
    image: null,
    name: "Al Barsha South",
    nameSlug: "Al-Barsha-South"
  },
  {
    id: 1234561,
    featured: false,
    image: null,
    name: "Al Jaddaf",
    nameSlug: "Al-Jaddaf"
  },
  {
    id: 1234561,
    featured: true,
    image: null,
    name: "Dubai Knowledge Park",
    nameSlug: "Dubai-Knowledge-Park"
  },

];


const LocationsSlider: FC<LocationsSliderProps> = ({
  heading = "Explore By Areas",
  subHeading = "Check stays by top locations",
  className = "",
  itemClassName = "",
  locations,
  itemPerRow = 5,
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS = "LocationsSlider__" + uniqueClassName;

  const { getLocationsData, getLocationsisSuccess } = useContext(FilterContext);

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS, getLocationsisSuccess]);

  const renderCard = (item: LocationsDataType, index: number) => {
    return <LocationCard key={index} location={item} />
  };

  return (

    <div className={`nc-LocationsSlider ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {
              getLocationsisSuccess &&
              getLocationsData?.map((item: LocationsDataType, index: number) => (item?.featured &&
                <li key={index} className={`glide__slide ${itemClassName}`}>
                  {renderCard(item, index)}
                </li>
              ))
            }
          </ul>
        </div>
        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default LocationsSlider;
