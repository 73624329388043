import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios from "axios";
import { useQuery } from "react-query";
import SocialLinks from "shared/SocialsList/SocialLinks";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    key: "address",
  },
  {
    title: "💌 EMAIL",
    key: "email",
    },
  {
    title: "☎ PHONE",
    key: "phone",
    },
];
const initialContactFormData = Object.freeze({
  fullName: "",
  email: "",
  phone: "",
  message: "",
});

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [contactFormData, updateContactFormData] = React.useState(initialContactFormData);

  /**get contact page details start */
  
    const { data: contactPage, isSuccess: contactPageIsSuccess } = useQuery(['contactPage'], async () => {
      const { data } = await axios.get('https://objects.bogportal.com/wp-json/wp/v2/pages/?slug=contact');
      return data[0];
    });
/**get contact page details end */


const handleContactFormChange = (e: { target: { name: any; value: string; }; }) => {
  updateContactFormData({
    ...contactFormData,

    // Trimming any whitespace
    [e.target.name]: e.target.value.trim()
  });
};

const handleContactForm = (e: { preventDefault: () => void; }) => {
  e.preventDefault()
  console.log(contactFormData);
  // ... submit to API or something
};

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || BeOurGuest - Holiday Homes</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {contactPage?.acf?.[item.key]}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialLinks className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleContactForm} >
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Your name"
                    type="text"
                    className="mt-1"
                    required
                    name="fullName"
                    onChange={handleContactFormChange}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    name="email"
                    onChange={handleContactFormChange}
                  />
                </label>
                <label className="block">
                  <Label>Phone</Label>
                  <PhoneInput
                    country={'us'}
                    value={contactFormData.phone}
                    onChange={phone => updateContactFormData((prevState) => ({...prevState, phone: phone,}))}
                    inputClass ="block !w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-500 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 !rounded-r-2xl text-sm font-normal !h-11 px-4 py-3 mt-1"
                    buttonClass="!rounded-l-2xl z-10 first:hover:bg-none"
                    enableSearch={true}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea className="mt-1" rows={6}  name="message" onChange={handleContactFormChange}/>
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default PageContact;
