import { createContext, useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import moment from "moment";



const BookingContext = createContext();

export const BookingProvider = ({children})=>{
    const [signupError, setsignupError] = useState('');
    const [loginError, setloginError] = useState('');

    const [signupMsg, setsignupMsg] = useState('');
    const [isLoggedin, setisLoggedin] = useState(false);

    /**Global booking State */
    const [bookingData, setbookingData] = useState({
        start_date : null,
        end_date: null,
        guests_count: null,
        isAdmin : null,
        isLoggedin: null,
        mobile : null,
        name : null,
        city: null,
        country: null,
    });
    const handleIncomingReservationClick = ()=>{

    }

    async function  calculatedBooking(propertyID, startDate, endDate, guestsCount){
        const { data } = await axios.get(`https://frontend-connect.bogportal.com/calculate-price/${propertyID}/priceDetails`, {
                params: {
                    ...( (startDate != "" && startDate != null) && {startingDate:moment(startDate,'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"),}),
                    ...( (endDate != "" && endDate != null) && {endingDate:moment(endDate,'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"),}),
                    ...( (guestsCount !== "") && {numberOfGuests:guestsCount,}),
                },
                headers: {
                    'Access-Control-Allow-Origin' : '*',
                },
                });
               // let result = data.price/getDifferenceInDays(moment(FilterData.start_date,'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"), moment(FilterData.end_date,'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"));
                //let result = Math.abs(data.price/startDate.diff(endDate,'days'));
                return data;
    }

    const doTheReservation = async (reservationData)=>{
        try{
            const { data, status } = await axios.get(`https://frontend-connect.bogportal.com/payment`, 
                    //reservationData,
                    {
                        // headers: {
                        //     'Access-Control-Allow-Origin' : 'http://localhost:3000',
                        // },
                    },
                    );
                    console.log(data);
                    if(status===200){
                        return data;
                    }else{
                        return data.errors;
                    }
                    
            }catch(error){
                console.log("get user error",error.response)
            }
    }
    

    return(
        <BookingContext.Provider value={{
            calculatedBooking,
            handleIncomingReservationClick,
            doTheReservation,
            bookingData,
        }}> {children}</BookingContext.Provider> 
    )
}


export default BookingContext;