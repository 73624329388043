import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Skeleton from "react-loading-skeleton";

export interface Card3Props {
  className?: string;
  post: any;
}

const Card3: FC<Card3Props> = ({ className = "h-full", post }) => {
  //const { title, href, featuredImage, desc, categories, postType } = post;
  //const post = (props)   = post;

  return (
    <div
      className={`nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group ${className}`}
      data-nc-id="Card3"
    >
      <div className="flex flex-col flex-grow">
        
        {(post?.post_slug!=null)?
        <div className="space-y-5 mb-4">
          <CategoryBadgeList categories={post?.categories_details} />
          <div>
          <Link to={`/blog/${post?.post_slug}`} className="line-clamp-2" title={post?.title?.rendered}>
            <h2
              className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}>
                
            </h2>
            </Link>
            <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-3"
              dangerouslySetInnerHTML={{__html: post?.excerpt?.rendered}}>
              </span>
            </div>
          </div>

          <PostCardMeta meta={{ ...post }} />
        </div>
        :<><Skeleton count={1} width={50} height={15}></Skeleton>
        <Skeleton count={3} className="w-full" height={15}></Skeleton></>}
      </div>
      {(post?.fimg_url!=null)?
      <div
        className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}
      >
        <Link
          to={`/blog/${post?.post_slug}`}
          className={`block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16 `}
        >
          <NcImage
            containerClassName="absolute inset-0"
            src={post?.fimg_url}
            //src={post?._embedded['wp:featuredmedia'][0]['source_url']}
            alt={post?.title?.rendered}
          />
          <span>
            <PostTypeFeaturedIcon
              className="absolute left-2 bottom-2"
              postType={"standard"}
              wrapSize="w-8 h-8"
              iconSize="w-4 h-4"
            />
          </span>
        </Link>
      </div>
      :<Skeleton className="w-full h-full" />}
    </div>
  );
};

export default Card3;
