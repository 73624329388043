import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Heading from "shared/Heading/Heading";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";

export interface OWNERS_FAQ {
    question: string;
    answer: string;
  }
  
  const OWNERS_FAQ: OWNERS_FAQ[] = [
    {
        question: "Are you an officially registered Holiday Homes Company? ",
        answer: `Yes. We are registered with both the DED and DTCM in Dubai. Our trade license number is 814890. `,
    },
    {
        question: "What is the duration of the contract between owner and Be Our Guest?",
        answer: `The usual contract is 12 months. However, we can tailor it on a case-by-case basis.`,
    },
    {
        question: "If I decide to terminate the contract, how do I do it?",
        answer: `The landlord may terminate the contract at any time by giving us a 30 days’ notice period. We are happy to return the apartment immediately, provided there is no guest currently there or checking in within the 30 days’ notice.`,
    },
    {
        question: "Who is responsible for the safety of my furniture and property?",
        answer: `Security deposits are collected from each guest to ensure no damages beyond the normal wear and tear are done. As an extra measure, some owners like to take an extra insurance policy that will also cover for not only their furniture, but also all the content in their property. The cost of such coverage will depend on how much you want to insure.`,
    },
    {
        question: "How will I receive my payments?",
        answer: `Payments will be deposited in the bank account provided by the landlord.`,
    },
    {
        question: "When will I receive my monthly report and payment?",
        answer: `Monthly reports are sent out on the first of the month and payments are paid on the tenth of every month. Should the first or tenth fall on a weekend or public holiday, they will be sent/paid on the next business day.`,
    },
    {
        question: "Is it necessary for my apartment to be furnished?",
        answer: `Yes. If it is unfurnished, we have a dedicated interior design team who can help you furnish it at competitive prices due to our strong relationships with many suppliers across the industry.`,
    },
    {
        question: "Can anyone do holiday homes?",
        answer: `No. It is only open to companies who have a license with the DED and DTCM in Dubai. If landlords want to do it on their own, they must get their permit from the DTCM.`,
    },
    {
        question: "How much are your fees to manage my property as a holiday home?",
        answer: `Our fees are 20% of the revenue earned.`,
    },
    {
        question: "What do your 20% management fees include?",
        answer: `
        •	All marketing and advertising costs associated in promoting your property.
        •	Check in/out of all guests into your property.
        •	Conducting all the viewings to your property before booking.
        •	Inspection of the property after each check-out to ensure your property is always well maintained.
        •	Cleaning and sanitization of the property as well as the change and laundry of linens and towels after each use.
        •	Handling all the monthly payments on your behalf.
        `,
    },
    {
        question: "What are the expenses that I have to pay for?",
        answer: `DEWA, chiller, gas, internet/TV, as well as any maintenance work. To ensure guests do not overuse DEWA and the chiller, we set a monthly cap limit where if they exceed this amount, the guest will have to pay the difference. This ensures to keep your monthly expenses fixed and controlled.`,
    },
    {
        question: "What measures are taken to ensure my property is well maintained during the guests stay?",
        answer: `It is in our interest that your property is always looking and functioning at its best. If it is not, we will not be able to rent it out. We make guests sign contracts stating that they will not damage the property and that they will follow community guidelines. Failure to do so will not only result from the security deposit being retained but can also result in immediate eviction.
        Where do you advertise the property? We advertise on major portals such as Booking.com, Airbnb, Property Finder, Bayut, Dubizzle…etc; just to name a few.
        `,
    },
    {
        question: "What is the DTCM?",
        answer: `The DTCM stands for the Department of Tourism and Commerce Marketing. They are the regulatory authority in Dubai regarding not only holiday homes but the whole tourism industry in the Emirate. All holiday home operators must obtain permits from them to operate for each unit they want to rent out. Those who do not obtain the permit are operating illegally.`,
    },
    {
        question: "What is the minimum and maximum number of nights a guest can rent the property for?",
        answer: `Minimum is 2 nights, and the maximum is 12 months.`,
    },
  ];

export interface OwnersFaqProps {
  className?: string;
}

const OwnersFaq: FC<OwnersFaqProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-OwnersFaq overflow-hidden relative ${className}`}
      data-nc-id="OwnersFaq"
    >
      <Helmet>
        <title>Frequently Asked Questions - Be Our Guest Dubai</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div
        className={`nc-SectionHero relative`}
        data-nc-id="SectionHero"
        >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
          Frequently Asked Questions?
          </h1>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            Check our frequently asked questions by property owners.
          </span>
        {/* {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>} */}
        </div>
        <div className="flex-grow ml-4">
          <img className="w-full rounded-xl" src="https://images.unsplash.com/photo-1553342047-1a988767f0de?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Njd8fHF1ZXN0aW9uc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60" alt="Interior Design" />
        </div>
      </div>
    </div>

    <div className="nc-SectionFounder relative my-32">
        {OWNERS_FAQ.map((item, index)=>(
            <Disclosure as="div" className="my-6">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-slate-50 px-4 py-2 text-left text-xl font-semibold hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75 dark:bg-slate-800">
                  <span>{item?.question}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-slate-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-base">
                    {item?.answer}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
            ))
        }
        
        
    </div>
    </div>
    </div>
  );
};

export default OwnersFaq;
