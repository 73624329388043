import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "components/SectionHero/SectionHero";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";


export interface TermsAndConditionsProps {
  className?: string;
}

const TermsAndConditions: FC<TermsAndConditionsProps> = ({ className = "TermsAndConditions" }) => {
  return (
    <div
      className={`nc-PrivacyPolicy overflow-hidden relative ${className}`}
      data-nc-id="PrivacyPolicy"
    >
      <Helmet>
        <title>Terms & Conditions - Be Our Guest Dubai</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div
      className={`relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col space-y-14 lg:space-y-0 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full space-y-5 lg:space-y-7">
          <h1 className="text-3xl self-start !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            Terms & Conditions - Be Our guest
          </h1>
        </div>
      </div>
    </div>
        <div className="flex flex-col space-y-8 lg:space-y-0  relative text-center lg:text-left">

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8 text-left">Welcome to Be Our Guest!</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">These terms and conditions outline the rules and regulations for the use of Be Our Guest's Website, located at https://beourguest.ae.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">By accessing this website we assume you accept these terms and conditions. Do not continue to use Be Our Guest if you do not agree to take all of the terms and conditions stated on this page.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same. Our Terms and Conditions were created with the help of the <a href="https://www.privacypolicyonline.com/terms-conditions-generator/">Terms & Conditions Generator</a>.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">Cookies</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">We employ the use of cookies. By accessing Be Our Guest, you agreed to use cookies in agreement with the Be Our Guest's Privacy Policy.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">License</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">Unless otherwise stated, Be Our Guest and/or its licensors own the intellectual property rights for all material on Be Our Guest. All intellectual property rights are reserved. You may access this from Be Our Guest for your own personal use subjected to restrictions set in these terms and conditions.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">You must not:</p>
<ul className="list-decimal">
    <li>Republish material from Be Our Guest</li>
    <li>Sell, rent or sub-license material from Be Our Guest</li>
    <li>Reproduce, duplicate or copy material from Be Our Guest</li>
    <li>Redistribute content from Be Our Guest</li>
</ul>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">This Agreement shall begin on the date hereof.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Be Our Guest does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Be Our Guest,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Be Our Guest shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">Be Our Guest reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">You warrant and represent that:</p>

<ul className="list-decimal">
    <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
    <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
    <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
    <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
</ul>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">You hereby grant Be Our Guest a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">Hyperlinking to our Content</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">The following organizations may link to our Website without prior written approval:</p>

<ul className="list-decimal">
    <li>Government agencies;</li>
    <li>Search engines;</li>
    <li>News organizations;</li>
    <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
    <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
</ul>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">We may consider and approve other link requests from the following types of organizations:</p>

<ul className="list-decimal">
    <li>commonly-known consumer and/or business information sources;</li>
    <li>dot.com community sites;</li>
    <li>associations or other groups representing charities;</li>
    <li>online directory distributors;</li>
    <li>internet portals;</li>
    <li>accounting, law and consulting firms; and</li>
    <li>educational institutions and trade associations.</li>
</ul>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Be Our Guest; and (d) the link is in the context of general resource information.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Be Our Guest. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">Approved organizations may hyperlink to our Website as follows:</p>

<ul className="list-decimal">
    <li>By use of our corporate name; or</li>
    <li>By use of the uniform resource locator being linked to; or</li>
    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
</ul>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">No use of Be Our Guest's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">iFrames</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">Content Liability</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">Reservation of Rights</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">Removal of links from our website</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

<h2 className="text-xl  self-start	md:text-4xl font-semibold dark:text-neutral-50 pt-8">Disclaimer</h2>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

<ul className="list-decimal">
    <li>limit or exclude our or your liability for death or personal injury;</li>
    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
</ul>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

<p className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
