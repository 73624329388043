// ProtectedRoute.tsx 
import React, { FC } from "react";
import { isLoggedIn } from "axios-jwt";
import {Navigate, Outlet} from 'react-router-dom'


const  ProtectedRoutes=(props:any) =>{
  const auth=isLoggedIn()
  return auth?<Outlet/>: <Navigate to="/login"/>
}

export default ProtectedRoutes;