import { createContext, useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";


const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [apiErrorResponse, setapiErrorResponse] = useState();
    const [listings, setlistings] = useState([]);

    /**Global Filter State */
    const [FilterData, setFilterData] = useState({
        location: "",
        start_date: "",
        end_date: "",
        guests_count: "",
        children_count: "",
        property_type: "",
        min_price: 0,
        max_price: 20000,
        bedrooms: "",
        amenities: "",
        sort: "",
        page: 1,
    });

    /**Filter Action reducer */
    const updateSearchFilter = (e, actionName) => {
        switch (actionName) {
            case "property_type":
                setFilterData((prevState) => ({ ...prevState, property_type: e, }));
                break;
            case "bedrooms":
                setFilterData((prevState) => ({ ...prevState, bedrooms: e, }));
                break;
            case "rangePrices":
                setFilterData((prevState) => ({ ...prevState, min_price: e[0], }));
                setFilterData((prevState) => ({ ...prevState, max_price: e[1], }));
                break;
            case "amenities":
                setFilterData((prevState) => ({ ...prevState, amenities: e, }));
                break;
            case "start_date":
                setFilterData((prevState) => ({ ...prevState, start_date: e, }));
                break;
            case "end_date":
                setFilterData((prevState) => ({ ...prevState, end_date: e, }));
                break;
            case "guests_count":
                setFilterData((prevState) => ({ ...prevState, guests_count: e, }));
                break;
            case "children_count":
                setFilterData((prevState) => ({ ...prevState, children_count: e, }));
                break;
            case "location":
                setFilterData((prevState) => ({ ...prevState, location: e, }));
                break;
            case "sort":
                setFilterData((prevState) => ({ ...prevState, sort: e, }));
                break;
            case "sortByPrice":
                setlistings(listings.sort(nestedSort("response", "price", "desc")));
                break;
            case "page":
                setFilterData((prevState) => ({ ...prevState, page: e, }));
                break;
            case "clearFilters":
                setFilterData((prevState) =>
                ({
                    ...prevState,
                    property_type: e,
                    bedrooms: e,
                    min_price: 0,
                    max_price: 20000,
                    amenities: e,
                    sort: e,
                }));
                break;
            case "clearAllFiltersExceptGuest":
                setFilterData((prevState) =>
                ({
                    ...prevState,
                    location: e,
                    start_date: e,
                    end_date: e,
                    property_type: e,
                    bedrooms: e,
                    min_price: 0,
                    max_price: 20000,
                    amenities: e,
                    sort: e,
                }));
                break;
            case "clearAllFilters":
                setFilterData((prevState) =>
                ({
                    ...prevState,
                    location: e,
                    start_date: e,
                    end_date: e,
                    guests_count: e,
                    children_count: e,
                    property_type: e,
                    bedrooms: e,
                    min_price: 0,
                    max_price: 20000,
                    amenities: e,
                    sort: e,
                }));
                break;
            default:
                break;
        }
    }

    /* api call for filter listings*/
    const { data: getListingsData, isLoading: getListingsLoading, isSuccess: getListingsisSuccess } = useQuery(
        ['getListings', FilterData],
        async () => {
            try {
                const { data } = await axios.get('https://frontend-connect.bogportal.com/listings', {
                    params: {
                        ...((FilterData.location !== "") && { location: FilterData.location, }),
                        ...((FilterData.bedrooms !== "") && { bedrooms_number: FilterData.bedrooms, }),
                        ...((FilterData.min_price != null && FilterData.min_price !== 0) && { min_price: FilterData.min_price, }),
                        ...((FilterData.max_price !== null && FilterData.max_price !== 20000) && { max_price: FilterData.max_price, }),
                        ...((FilterData.amenities !== "") && { amenities: FilterData.amenities, }),
                        ...((FilterData.property_type !== "") && { property_type: FilterData.property_type, }),
                        ...((FilterData.start_date != "" && FilterData.start_date != null) && { start_date: moment(FilterData.start_date, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"), }),
                        ...((FilterData.end_date != "" && FilterData.end_date != null) && { end_date: moment(FilterData.end_date, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"), }),
                        ...((FilterData.guests_count !== "") && { guests_count: FilterData.guests_count, }),
                        ...((FilterData.sort !== "") && { sort: FilterData.sort, }),
                    },
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                });
                // if( (FilterData.start_date != "" && FilterData.start_date != null) &&
                //      (FilterData.end_date != "" && FilterData.end_date != null) &&
                //       (FilterData.guests_count != "")){
                //      const newdata = data;
                //      newdata.response.forEach(item=>{
                //                   calculatePrice(item.propertyId).then(value => item.price = value);
                //           })
                //      console.log("updated price from axios",newdata);

                //     return data;
                //     }
                return data;
            }
            catch (error) {
                setapiErrorResponse(error.response.status);
                //window.location = "/404";
                console.log(error.response.status);
            }
        },
        { staleTime: 900000, }
    );



    const calculatePrice = async (propertyID) => {
        const { data } = await axios.get(`https://frontend-connect.bogportal.com/calculate-price/${propertyID}/priceDetails`, {
            params: {
                ...((FilterData.start_date != "" && FilterData.start_date != null) ? { startingDate: moment(FilterData.start_date, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"), } : { startingDate: moment().format('YYYY-MM-DD'), }),
                ...((FilterData.end_date != "" && FilterData.end_date != null) ? { endingDate: moment(FilterData.end_date, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"), } : { endingDate: moment().add(7, "days").format("YYYY-MM-DD"), }),
                ...((FilterData.guests_count !== "") ? { numberOfGuests: FilterData.guests_count, } : { numberOfGuests: 2, }),
            },
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        // let result = data.price/getDifferenceInDays(moment(FilterData.start_date,'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"), moment(FilterData.end_date,'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD"));
        let result;
        if ((FilterData.start_date != "" && FilterData.start_date != null) &&
            (FilterData.end_date != "" && FilterData.end_date != null)) {
            result = Math.abs(data.price / moment().diff(FilterData.end_date, 'days'));
        } else {
            result = Math.abs(data.price / moment().add(7, "days").diff(FilterData.end_date, 'days'));
        }

        return result;
    }


    useEffect(() => {
        setlistings(getListingsData);
        /**if condition to get the price only if start and end dates are available, this will go in below if 
            (FilterData.start_date != "" && FilterData.start_date != null) &&
             (FilterData.end_date != "" && FilterData.end_date != null) &&
              (FilterData.guests_count != "") */
        if (getListingsisSuccess && (FilterData.start_date != "" && FilterData.start_date != null) &&
            (FilterData.end_date != "" && FilterData.end_date != null) &&
            (FilterData.guests_count != "")) {
            const Listingslist = { ...getListingsData };
            Listingslist.response.forEach(item => {
                calculatePrice(item.propertyId).then(value => item.price = value);
            });
            const returnedListings = Object.assign(getListingsData, Listingslist);
            //  setlistings((prevState) => 
            //     ({...prevState, 
            //         response: Listingslist,
            //     }));
            setlistings(returnedListings);
        }
    }, [getListingsisSuccess])

    useLayoutEffect(() => {
        console.log("listingslist", listings)
    }, [listings])


    useEffect(() => {
        console.log({ FilterData });
    }, [FilterData])



    const test = {
        'result': [
            {
                'count': 18,
                'featured': true,
                'id': 25,
                'image': 'https://photos2.theblueground.com/1520/pg12332-o-149a4986-6238-1f73-7f0e-9f3ded90bf2d.jpg',
                'name': 'Al Furjan',
                'nameSlug': 'al-furjan'
            }, {
                'count': 13,
                'featured': true,
                'id': 6,
                'image': 'https://photos2.theblueground.com/1520/pg12159-o-b23d6c88-5f4f-e579-c87b-21fbef5351bf.jpg',
                'name': 'Al Jaddaf',
                'nameSlug': 'al-jaddaf'
            }, {
                'count': 13,
                'featured': true,
                'id': 19,
                'image': 'https://cdn.theblueground.com/website/static/img/dubai-1-large.09b81e0ed676f3132bb9.jpg',
                'name': 'Al Quoz',
                'nameSlug': 'al-quoz'
            }, {
                'count': 13,
                'featured': true,
                'id': 13,
                'image': 'https://cdn.theblueground.com/website/static/img/dubai-4-large.1bfb48ce9c1fc420926b.jpg',
                'name': 'Arjan',
                'nameSlug': 'arjan'
            }, {
                'count': 13,
                'featured': true,
                'id': 12,
                'image': 'https://cdn.theblueground.com/website/static/img/dubai-7-large.33a9e3f45799292a37c9.jpg',
                'name': 'Business Bay',
                'nameSlug': 'business-bay'
            }, {
                'count': 13,
                'featured': true,
                'id': 11,
                'image': 'https://photos2.theblueground.com/1520/pg20181-o-0f48c2ab-feb3-589a-16d8-49fe8f5e8a39.jpg',
                'name': 'Damac Hills',
                'nameSlug': 'damac-hills'
            }, {
                'count': 13,
                'featured': true,
                'id': 23,
                'image': 'https://photos2.theblueground.com/1520/pa13922-o-855742f8-4592-f3b5-5f63-0ec0e3cfae8b.jpg',
                'name': 'Damac Hills 2',
                'nameSlug': 'damac-hills-2'
            }, {
                'count': 13,
                'featured': true,
                'id': 3,
                'image': 'https://photos2.theblueground.com/1520/pg13922-o-38527576-0ce7-0056-af87-6d373e121e66.jpg',
                'name': 'Downtown',
                'nameSlug': 'downtown'
            }, {
                'count': 13,
                'featured': true,
                'id': 10,
                'image': 'https://photos2.theblueground.com/1520/pg13922-o-b0c66f99-67fb-78ce-ec06-34232cdedf51.jpg',
                'name': 'Dubai Creek',
                'nameSlug': 'dubai-creek'
            }, {
                'count': 13,
                'featured': true,
                'id': 21,
                'image': 'https://photos2.theblueground.com/1520/pg13922-o-da3f9e50-b4c8-c074-4343-552aac40aa00.jpg',
                'name': 'Dubai Festival City',
                'nameSlug': 'dubai-festival-city'
            }, {
                'count': 13,
                'featured': true,
                'id': 4,
                'image': 'https://photos2.theblueground.com/1520/pg13922-o-3cd12b59-ae08-e155-6ae0-1d01d8bfc498.jpg',
                'name': 'Dubai Hills',
                'nameSlug': 'dubai-hills'
            }, {
                'count': 13,
                'featured': true,
                'id': 2,
                'image': 'https://photos2.theblueground.com/1520/pg19656-o-d056d2eb-45a9-0c64-7d48-172e461e2791.jpg',
                'name': 'Dubai Marina',
                'nameSlug': 'dubai-marina'
            }, {
                'count': 13,
                'featured': true,
                'id': 18,
                'image': 'https://photos2.theblueground.com/1520/pg19656-o-0487032b-9b78-7a3f-4c3e-761d8b07bb44.jpg',
                'name': 'Emaar Beachfront',
                'nameSlug': 'emaar-beachfront'
            }, {
                'count': 13,
                'featured': true,
                'id': 1,
                'image': 'https://photos2.theblueground.com/1520/pg19673-o-54f7f8fe-30a3-311a-9473-551512fa266b.jpg',
                'name': 'JBR',
                'nameSlug': 'jbr'
            }, {
                'count': 13,
                'featured': true,
                'id': 14,
                'image': 'https://photos2.theblueground.com/1520/pg19673-o-00d175bd-6e9c-5898-061f-7cf9253f86c5.jpg',
                'name': 'JLT',
                'nameSlug': 'jlt'
            }, {
                'count': 13,
                'featured': true,
                'id': 7,
                'image': 'https://photos2.theblueground.com/1520/pg19985-o-cdde3ae0-fc76-e327-e85c-684985d15a24.jpg',
                'name': 'JVC',
                'nameSlug': 'jvc'
            }, {
                'count': 13,
                'featured': true,
                'id': 5,
                'image': 'https://photos2.theblueground.com/1520/pg19985-o-f2b41545-bfd5-64ff-b545-7b7eabb90895.jpg',
                'name': 'JVT',
                'nameSlug': 'jvt'
            }, {
                'count': 13,
                'featured': true,
                'id': 22,
                'image': 'https://photos2.theblueground.com/1520/pg19985-o-a8e40d4e-089e-2568-d95f-718defbaaa43.jpg',
                'name': 'Mudon',
                'nameSlug': 'mudon'
            }, {
                'count': 13,
                'featured': true,
                'id': 15,
                'image': 'https://photos2.theblueground.com/1520/pg15270-o-09cb9d73-c708-9422-26df-6a72f9f4152a.jpg',
                'name': 'Palm Jumeirah',
                'nameSlug': 'palm-jumeirah'
            }, {
                'count': 13,
                'featured': true,
                'id': 24,
                'image': 'https://photos2.theblueground.com/1520/pg19055-o-ec612631-dc6c-28fb-7788-249194747e00.jpg',
                'name': 'Production City',
                'nameSlug': 'production-city'
            }, {
                'count': 13,
                'featured': true,
                'id': 17,
                'image': 'https://photos2.theblueground.com/1520/pg19055-o-b6c698e5-4cd1-4fdd-c5ca-6b29b5f4f536.jpg',
                'name': 'Sobha Heartland',
                'nameSlug': 'sobha-heartland'
            }, {
                'count': 13,
                'featured': true,
                'id': 16,
                'image': 'https://photos2.theblueground.com/1520/pg14710-o-18c2db0b-4057-558d-e6c0-6544a46c5d49.jpg',
                'name': 'Sports City',
                'nameSlug': 'sports-city'
            }, {
                'count': 13,
                'featured': true,
                'id': 33,
                'image': 'https://photos2.theblueground.com/1520/pg14710-o-ef655015-bc99-57c2-c97f-58bbe1f97178.jpg',
                'name': 'Studio',
                'nameSlug': 'studio'
            }, {
                'count': 13,
                'featured': true,
                'id': 8,
                'image': 'https://photos2.theblueground.com/1520/pg14710-o-31ea99df-4939-2072-7536-ca6abedf3cd1.jpg',
                'name': 'Tecom',
                'nameSlug': 'tecom'
            }, {
                'count': 13,
                'featured': true,
                'id': 20,
                'image': 'https://cdn.theblueground.com/website/static/img/kids-large.c46c7cd12272a3d28531.jpg',
                'name': 'Town Square',
                'nameSlug': 'town-square'
            }
        ]
    }


    /**Location fetch Api */
    const { data: getLocationsData, isLoading: getLocationsLoading, isSuccess: getLocationsisSuccess } = useQuery(
        'getLocations',
        async () => {
            const { data } = await axios.get('https://frontend-connect.bogportal.com/locations',
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                });
                
            // return data.result;
            return test.result;

        },
        { staleTime: 1800000, }
    );

    return (
        <FilterContext.Provider value={{
            FilterData,
            updateSearchFilter,
            getListingsData,
            getListingsLoading,
            getListingsisSuccess,
            getLocationsData,
            getLocationsisSuccess,
            apiErrorResponse,
            setapiErrorResponse,
            listings,
        }}> {children}</FilterContext.Provider>
    )
}

function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
}

const nestedSort = (prop1, prop2 = null, direction = 'asc') => (e1, e2) => {
    const a = prop2 ? e1[prop1][prop2] : e1[prop1],
        b = prop2 ? e2[prop1][prop2] : e2[prop1],
        sortOrder = direction === "asc" ? 1 : -1
    return (a < b) ? -sortOrder : (a > b) ? sortOrder : 0;
}
export default FilterContext;