import React, { useEffect, useLayoutEffect, useState } from "react";
import { DEMO_POSTS } from "data/posts";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { useQuery } from "react-query";
import axios from "axios";
import { useParams } from "react-router-dom";

// // DEMO DATA
// const POSTS = DEMO_POSTS;

// // DEMO POST FOR MAGAZINE SECTION
// const MAGAZINE1_POSTS = POSTS.filter((_, i) => i >= 0 && i < 8);
//

const BlogPage: React.FC = () => {
const { category } = useParams();
const [totalPosts, settotalPosts] = useState<number>(0);
const [totalPages, settotalPages] = useState<number>(0);
const [currentPage, setcurrentPage] = useState<number>(1);
  useEffect(() => {
    if(category!=""){
      setcurrentPage(1);
    }
  }, [category]);


/* api call for Blog posts*/
  const {data: getPostsData, isLoading: getPostsLoading, isSuccess: getPostsisSuccess } = useQuery(
    ['getPosts',currentPage, category], 
    async () => {
      try{
          const { data, headers } = await axios.get('https://objects.bogportal.com/wp-json/wp/v2/posts', {
          params: {
              ...( (category !== "") && {category_slug: category,}),
              ...( {per_page: 10,}),
              ...( (currentPage !== 0) && {page: currentPage,}),
          },
          //headers: {
              //'Access-Control-Allow-Origin' : '*',       
          //},
          });
            settotalPosts(parseInt(headers["x-wp-total"]));
            settotalPages(parseInt(headers["x-wp-totalpages"]));
            return data;
      }
      catch (error) { 
          console.log("posts error",error.data.message);
      }
    },
    {cacheTime: 3900000,}
    );
    //popular posts api
    const {data: getPopularPostsData, isLoading: getPopularPostsLoading, isSuccess: getPopularPostsisSuccess } = useQuery(
      ['getPopularPosts'], 
      async () => {
        try{
            const { data } = await axios.get('https://objects.bogportal.com/wp-json/wp/v2/posts', {
            params: {
              meta_key: 'views',
              per_page: '10',
            },
            //headers: {
                //'Access-Control-Allow-Origin' : '*',       
            //},
            });
              return data;
        }
        catch (error) { 
            console.log("Popular posts",error.response.status); 
        }
      },
      {cacheTime: 3900000,}
      );

      //categories api
    const {data: getCategoriesData, isLoading: getCategoriesLoading, isSuccess: getCategoriesisSuccess } = useQuery(
      ['getCategories'], 
      async () => {
        try{
            const { data } = await axios.get('https://objects.bogportal.com/wp-json/wp/v2/categories', {
            // params: {
            //   meta_key: 'views',
            //   per_page: '10',
            // },
            //headers: {
                //'Access-Control-Allow-Origin' : '*',       
            //},
            });
              return data;
        }
        catch (error) { 
            console.log("Categories error",error.response.status); 
        }
      },
      {cacheTime: 3900000,}
      );
  
  /**pagination */
  
  
  const handlePaginationClick=(item: number)=>{
    setcurrentPage(item);
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
  }


  
  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Blog - Be Our Guest</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        {/* === SECTION 1 === */}
        {/* <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 posts={MAGAZINE1_POSTS} />
        </div> */}

        {/* === SECTION 1 === */}
        {/* <SectionAds /> */}

        {/* === SECTION 8 === */}
        
        <SectionLatestPosts 
        posts={getPostsData} 
        popularposts={getPopularPostsData} 
        isApiSuccess={getPostsisSuccess} 
        categories={getCategoriesData} 
        totalPages={totalPages}
        totalPosts={totalPosts}
        handlePagination={handlePaginationClick}
        currentPage = {currentPage}
        category={category}
        className="py-16 lg:py-28" />
        
        {/* === SECTION 1 === */}
        <SectionSubscribe2 className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

const handleCapitalCase = (mySentence: string | undefined)=>{
  return mySentence?.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}
export default BlogPage;

