import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialLinksProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: "lab la-facebook", href: "" },
  { name: "Twitter", icon: "lab la-twitter", href: "https://twitter.com/intent/tweet?text=" },
  { name: "Linkedin", icon: "lab la-linkedin", href: "#" },
  { name: "WhatsApp", icon: "lab la-whatsapp", href: "#" },
];

const SocialLinks: FC<SocialLinksProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`nc-SocialLinks flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialLinks"
    >
        <a
          className={`${itemClass}`}
          href={`https://www.facebook.com/`}
          target="_blank"
          rel="noopener noreferrer"
          title={'Facebook'}
        >
          <i className={"lab la-facebook"}></i>
        </a>

        <a
          className={`${itemClass}`}
          href={`https://twitter.com/}`}
          target="_blank"
          rel="noopener noreferrer"
          title={"Twitter"}
        >
          <i className={"lab la-twitter"}></i>
        </a>

        <a
          className={`${itemClass}`}
          href={`https://www.linkedin.com/`}
          target="_blank"
          rel="noopener noreferrer"
          title={"Linkedin"}
        >
          <i className={"lab la-linkedin"}></i>
        </a>

        <a
          className={`${itemClass}`}
          href={`https://wa.me/+971555555555`}
          target="_blank"
          rel="noopener noreferrer"
          title={"WhatsApp"}
        >
          <i className={"lab la-whatsapp"}></i>
        </a>
    </nav>
  );
};

export default SocialLinks;
