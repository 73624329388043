import React from "react";
import MyRouter from "routers/index";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <ReactNotifications />
      <MyRouter children={undefined} />
    </div>
  );
}

export default App;
