import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import homeHeroImg from "images/hero-right.jpg";
import HomeSearchForm from "components/HeroSearchForm/HomeSearchForm";
import {useQuery} from 'react-query'
import axios from 'axios'
import Skeleton,  { SkeletonTheme }  from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export interface SectionHeroProps {
  className?: string;
  heading?: string;
  subheading?:string;
}

const SectionHero: FC<SectionHeroProps> = ({ 
  className = "",
  heading,
  subheading,
 }) => {

  

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
        <SkeletonTheme>
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
           {heading || <Skeleton height={20} width={500}/>}
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          {subheading || <Skeleton count={2} height={15} width={300}/>}
          </span>
          </SkeletonTheme>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={homeHeroImg} alt="hero" />
        </div>
      </div>

      <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HomeSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
