import React, { FC, Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput, { GuestsInputProps } from "components/HeroSearchForm/GuestsInput";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import ShareButton from "./ShareButton";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import LocationsSlider from "components/LocationsSlider/Locationslider";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Helmet } from "react-helmet";
import BookingContext from "context/BookingContext";
import SocialsList from "shared/SocialsList/SocialsList";


export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

type priceDetails = {
  totalNights?: number | null,
  totalNightsFee?: number | null,
  pricePerNight?: number | null,
  cleaningFee?: number | null,
  damageDeposit?: number | null,
  monthlyDiscount?: number | null,
  weeklyDiscount?: number | null,
  TotalPrice?: number | null,
}
const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    // startDate: moment(),
    // endDate: moment().add(3, "days"),
    startDate: null,
    endDate: null,
  });
  const [guestValue, setGuestValue] = useState<GuestsInputProps["defaultValue"]>({ guestAdults: 2, guestChildren: 0 });

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  const [PriceDetails, setPriceDetails] = useState<priceDetails>({
    totalNights: null,
    totalNightsFee: null,
    pricePerNight: null,
    cleaningFee: null,
    damageDeposit: null,
    monthlyDiscount: null,
    weeklyDiscount: null,
    TotalPrice: null,
  })

  const [aroundBlockedDates, setaroundBlockedDates] = useState("");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  let [priceDetailsisLoading, setpriceDetailsisLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get('sd');
  const endDate = searchParams.get('ed');
  const guests = searchParams.get('guests');

  useEffect(() => {

    if (startDate != null && endDate != null && guests != null) {
      setSelectedDate((prevState: any) => ({ ...prevState, startDate: moment(startDate), endDate: moment(endDate), }));
      setGuestValue((prevState: any) => ({ ...prevState, guestAdults: guests, }));
    }
    return () => {
      setSelectedDate((prevState: any) => ({ ...prevState, startDate: null, endDate: null, }));
      setGuestValue((prevState: any) => ({ ...prevState, guestAdults: 2, }));
    }
  }, [startDate, endDate, guests])

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  //Get slug from url
  let { slug } = useParams();
  let propID: string[] | undefined = slug?.split("-");
  const getpropID: string | undefined = propID ? propID[propID.length - 1] : '';



  /**api call for listing details start */
  const fetchSingleListing = async () => {
    const { data } = await axios.get(`https://frontend-connect.bogportal.com/listings/?slug=` + slug, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });
    return data.response;
  }
  const { data: SingleListingData, isLoading: SLDLoading, isSuccess: SLDisSuccess, error: SLDerror } = useQuery('fetchSingleListing', fetchSingleListing, { cacheTime: 9000000, });
  /**api call for listing details end */





  /* api call for getting calendar & logics related to it*/
  const { calculatedBooking } = useContext(BookingContext);

  const { data: getCalendar, isLoading: getCalendarisLoading, isSuccess: getCalendarisSuccess } = useQuery(
    ['getCalendar', SingleListingData], async () => {
      if (SLDisSuccess) {
        try {
          const { data } = await axios.get(`https://frontend-connect.bogportal.com/calendar/${getpropID}`, {
            headers: {
              'Access-Control-Allow-Origin': '*',
            }
          });
          let blockedDates: any[] = [];
          data.result.forEach((dateItem: { isAvailable: any; date: moment.MomentInput; }) => {
            (!dateItem.isAvailable) && blockedDates.push(moment(dateItem.date))
          });
          return blockedDates;
        }
        catch (error) {
          console.log("error getting calendar", error.response.status);
        }
      }
    },
    { cacheTime: 900000, }
  );

  useLayoutEffect(() => {
    if (selectedDate.startDate != null && selectedDate.endDate != null) {
      //below will update the PriceDetails state which holds all calculation values
      if (guestValue.guestAdults != 0) {
        setpriceDetailsisLoading(true);
        setaroundBlockedDates("");
        calculatedBooking(getpropID, selectedDate.startDate, selectedDate.endDate, guestValue.guestAdults).then((data: any) => {
          setPriceDetails((prevState: any) => ({
            ...prevState,
            totalNights: selectedDate.startDate?.diff(selectedDate.endDate, 'days'),
            totalNightsFee: data.price,
            cleaningFee: data.cleaningFee,
            damageDeposit: data.refundableDamageDeposit,
            monthlyDiscount: data.monthlyDiscount,
            weeklyDiscount: data.weeklyDiscount,
            TotalPrice: data.totalPrice,
          }));
        });
        setpriceDetailsisLoading(false);
      }
      //check is start date & end date has blocked dates in between & then clear pricing state
      getCalendar?.every((blockedDate) => {
        if (moment(moment(blockedDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")).isBetween(selectedDate.startDate, selectedDate.endDate, 'day')) {
          setaroundBlockedDates("You're selecting dates around blocked dates");
          console.info("istrue has blocked dates carefull");
          setSelectedDate((prevState) => ({ ...prevState, startDate: null, }));
          setSelectedDate((prevState) => ({ ...prevState, endDate: null, }));
          setGuestValue((prevState) => ({ ...prevState, guestAdults: 0, }));
          setPriceDetails((prevState) =>
          ({
            ...prevState,
            totalNights: null,
            totalNightsFee: null,
            pricePerNight: null,
            cleaningFee: null,
            damageDeposit: null,
            monthlyDiscount: null,
            weeklyDiscount: null,
            TotalPrice: null,
          }));
          return true;
        }
      })

    }
    //validate min stays & show message
    else if (selectedDate.startDate != null && selectedDate.endDate != null && selectedDate.startDate?.diff(selectedDate.endDate, 'days') < SingleListingData.minNights) {
      setaroundBlockedDates(`Min stay is ${SingleListingData.minNights}, kindly change your dates`);
    }//clear price details state
    else if (selectedDate.startDate == null || selectedDate.endDate == null || guestValue.guestAdults == 0) {
      clearPriceDetailsState();
    }

  }, [selectedDate.startDate, selectedDate.endDate, guestValue.guestAdults]);


  const clearPriceDetailsState = () => {
    setPriceDetails((prevState) =>
    ({
      ...prevState,
      totalNights: null,
      totalNightsFee: null,
      pricePerNight: null,
      cleaningFee: null,
      damageDeposit: null,
      monthlyDiscount: null,
      weeklyDiscount: null,
      TotalPrice: null,
    }));
  }
  /**API call ended for getting calendar*/






  /**stay rangeDate component props logic */
  const findBlockedDay = (day: moment.Moment) => {
    let found: any;
    if (getCalendarisSuccess) {
      found = getCalendar?.some((blockedday: any) => blockedday.isSame(day, 'day'));
    }
    if (found === true) {
      return found;
    }
    if (found === undefined) {
      return undefined;
    }
    else {
      return false;
    }
  }
  /**stay rangeDate component props logic end */

  /**Reserve logic start */
  const handleReserveBtn = () => {

  }
  /**Reserve logic end */

  const guestInputHandler = (data: any) => {
    setGuestValue(data);
    if (data.guestAdults == 0) {
      clearPriceDetailsState();
    }
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={SingleListingData?.roomType.replaceAll('_', ' ').toUpperCase()} />
          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button>
                  <div className="flow-root">
                    <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
                      <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                          />
                        </svg>
                        <span className="hidden sm:block ml-2.5">Share</span>
                      </span>
                    </div>
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 w-md max-w-xs px-1 mt-2 right-0 sm:px-0 lg:max-w-xs">
                    <div className="overflow-hidden rounded-xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                      <div className="relative flex flex-col px-2 py-2 space-y-4">
                        <SocialsList />
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {SingleListingData?.name.slice(SingleListingData?.name.indexOf('·') + 1) || <Skeleton count={2} height={25} className="w-full" />}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/*<StartRating />
          <span>·</span>*/}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {SingleListingData?.suburb}, {SingleListingData?.state}</span>
          </span>
        </div>

        {/* 4 
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Be Our Guest
            </span>
          </span>
        </div>*/}2

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {SingleListingData?.personCapacity} <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {SingleListingData?.bedsNumber} <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {SingleListingData?.bathroomsNumber} <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              {SingleListingData?.bedroomsNumber} <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {SingleListingData?.description || <Skeleton count={4} height={20} className="w-full" />}
          </span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {SingleListingData?.listingAmenities?.filter((_: any, i: number) => i < 12).map((item: { amenityId: number, name: {} | null | undefined; icon: any; }) => (
            <div key={item.amenityId} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View all amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {SingleListingData?.listingAmenities?.filter((_: any, i: number) => i < 1212).map((item: { amenityId: number, name: {} | null | undefined; icon: any; }) => (
                      <div
                        key={item.amenityId}
                        className="flex items-center py-6 space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>AED{SingleListingData?.price}</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>AED{SingleListingData?.price}</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>AED{SingleListingData?.price}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>{(SingleListingData?.minNights == 1) ? SingleListingData?.minNights + ' night' : SingleListingData?.minNights + " nights"}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>{SingleListingData?.maxNights} nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        {(!getCalendarisLoading) ?
          <div className="listingSection__wrap__DayPickerRangeController flow-root">
            <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
              <DayPickerRangeController
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
                onDatesChange={(date) => setSelectedDate(date)}
                focusedInput={focusedInputSectionCheckDate}
                onFocusChange={(focusedInput) =>
                  setFocusedInputSectionCheckDate(focusedInput || "startDate")
                }
                initialVisibleMonth={null}
                numberOfMonths={windowSize.width < 1280 ? 1 : 2}
                daySize={getDaySize()}
                hideKeyboardShortcutsPanel={false}
                renderDayContents={(day: any) => {
                  return (
                    (moment(day).isBefore(moment())) ?
                      <div className='searchDay'
                      >{moment(day).format('D')}</div> :
                      (findBlockedDay(day) === true) ?
                        //if true
                        <div className='searchDay text-neutral-300 cursor-not-allowed'
                        >{moment(day).format('D')}<p className="text-[0.55em] text-red-600">booked</p></div> :
                        //if false
                        <div className='searchDay'
                        >{moment(day).format('D')}<p className="text-[0.55em] text-green-600">Available</p></div>
                  )
                }}
                isDayBlocked={day => findBlockedDay(day)}
                isOutsideRange={(day) => moment(day).isBefore(moment())}
                minimumNights={SingleListingData?.minNights}
              />
            </div>
          </div>
          :
          <div>
            <Skeleton count={2} height={25} width={400} />
            <Skeleton count={2} height={25} width={600} />
          </div>}
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {SingleListingData?.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            {(SingleListingData?.lat) ?
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBIK0ZQZu_5KdKjIESKVyQOI7JSu4Xh8nc",
                }}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                defaultCenter={{
                  lat: SingleListingData?.lat,
                  lng: SingleListingData?.lng,
                }}
              >
                <LocationMarker lat={SingleListingData.lat} lng={SingleListingData.lng} />
              </GoogleMapReact>
              : <Skeleton className="aspect-[4/3]" />}
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation Policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            This reservation is non-refundable.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">House Rules</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>{tConvert(SingleListingData?.checkInTimeStart + `:00`)}</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>{tConvert(SingleListingData?.checkOutTime + `:00`)}</span>
            </div>
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Pets</span>
              <span>{(SingleListingData?.maxPetsAllowed == null) ? `Not allowed` : `Allowed`}</span>
            </div>
            {/* <div className="flex space-x-10 justify-between p-3">
              <span>Smoking inside</span>
              <span>Allowed</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Parties and events</span>
              <span>Allowed</span>
            </div> */}
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}


        <div className="flex flex-col justify-between">
          <span className="text-sm pb-2">Min stay is {SingleListingData?.minNights} nights</span>
          {(PriceDetails.totalNightsFee != null && PriceDetails.totalNights != null && aroundBlockedDates == '') ?
            <span className="text-3xl font-semibold">
              AED {Math.abs(PriceDetails.totalNightsFee / PriceDetails.totalNights).toFixed(2)}
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /night
              </span>
            </span> : ''}</div>
        {/* <StartRating /> */}


        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={1}
            fieldClassName="p-5"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
            renderDayContents={(day: any) => {
              return (
                (moment(day).isBefore(moment())) ?
                  <div className='searchDay'
                  >{moment(day).format('D')}</div> :
                  (findBlockedDay(day)) ?
                    //if true
                    <div className='searchDay text-neutral-300'
                    >{moment(day).format('D')}<p className="text-[0.55em] text-red-600">booked</p></div> :
                    //if false
                    <div className='searchDay'
                    >{moment(day).format('D')}<p className="text-[0.55em] text-green-600">Available</p></div>
              )
            }}
            isDayBlocked={day => findBlockedDay(day)}
            minimumNights={SingleListingData?.minNights}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            fieldClassName="p-5"
            defaultValue={guestValue}
            onChange={(data) => {
              guestInputHandler(data)
            }}
          />
        </form>

        {/* SUM */}
        {(aroundBlockedDates != '') ?
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between text-red-600 dark:text-neutral-300">
              <span>{aroundBlockedDates}</span>
            </div>
          </div> : ''
        }


        {(selectedDate.startDate != null && selectedDate.endDate != null && guestValue.guestAdults != 0 && priceDetailsisLoading) ?
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span><Skeleton count={2} height={25} /></span>
              <span><Skeleton count={2} height={25} /></span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span><Skeleton count={2} height={25} /></span>
              <span><Skeleton count={2} height={25} /></span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span><Skeleton count={2} height={25} /></span>
              <span><Skeleton count={2} height={25} /></span>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span><Skeleton count={2} height={25} /></span>
              <span><Skeleton count={2} height={25} /></span>
            </div>
          </div>
          : (PriceDetails.totalNightsFee != null && PriceDetails.totalNights != null && aroundBlockedDates === "" && !priceDetailsisLoading) ?
            <><div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>AED {Math.abs(PriceDetails.totalNightsFee / PriceDetails.totalNights).toFixed(2)} x {Math.abs(PriceDetails.totalNights)} night</span>
                <span>AED {PriceDetails.totalNightsFee}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Cleaning Fee</span>
                <span>AED {PriceDetails.cleaningFee}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Damage Deposit</span>
                <span>AED {PriceDetails.damageDeposit}</span>
              </div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>Total Price</span>
                <span>AED {Math.abs(PriceDetails.totalNightsFee / PriceDetails.totalNights).toFixed(2)}</span>
              </div>
            </div>
              <Link to={`/checkout?property=${slug}&propid=${getpropID}&sd=${moment(selectedDate.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")}&ed=${moment(selectedDate.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")}&guests=${guestValue.guestAdults}`} ><ButtonPrimary loading={priceDetailsisLoading} onClick={handleReserveBtn}>Reserve</ButtonPrimary></Link></> :
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Select dates to get the pricing details</span>
              </div>
            </div>}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${SingleListingData?.name} - BeOurGuest Dubai`}</title>
      </Helmet>
      <div
        className={`nc-ListingStayDetailPage  ${className}`}
        data-nc-id="ListingStayDetailPage"
      >
        {/* SINGLE HEADER */}
        <>
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            {SingleListingData ?
              <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                <div
                  className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                  onClick={() => handleOpenModal(0)}
                >

                  <NcImage
                    containerClassName="absolute inset-0"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                    src={SingleListingData?.listingImages[0]?.url}
                  />
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                </div>
                {SingleListingData?.listingImages?.filter((_: any, i: number) => i >= 1 && i < 5).map((item: { sortOrder: number, text: string | undefined, url: string }, index: number) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item.url || ""}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

                <div
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                  onClick={() => handleOpenModal(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                    Show all photos
                  </span>
                </div>
              </div> :
              <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                <div className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer">
                  <Skeleton className="h-full" />
                </div>
                <div className="relative rounded-md sm:rounded-xl overflow-hidden"><Skeleton className="nc-NcImage aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 h-full" /></div>
                <div className="relative rounded-md sm:rounded-xl overflow-hidden"><Skeleton className="nc-NcImage aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 h-full" /></div>
                <div className="relative rounded-md sm:rounded-xl overflow-hidden"><Skeleton className="nc-NcImage aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 h-full" /></div>
                <div className="relative rounded-md sm:rounded-xl overflow-hidden hidden sm:block"><Skeleton className="nc-NcImage aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 h-full" /></div>
              </div>}
          </header>
          {/* MODAL PHOTOS */}
          <ModalPhotos
            imgs={SingleListingData?.listingImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
          />
        </>

        {/* MAIn */}
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            {renderSection1()}
            {renderSection2()}
            {renderSection3()}
            {/*renderSection4() - room rates*/}
            {renderSectionCheckIndate()}
            {/*renderSection5() - host info */}
            {/*renderSection6() - reviews */}
            {renderSection7()}
            {renderSection8()}
          </div>

          {/* SIDEBAR */}
          <div className="block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-24">{renderSidebar()}</div>
          </div>
        </main>

        {/* STICKY FOOTER MOBILE */}
        {!isPreviewMode && (
          <div className="block lg:hidden fixed bottom-0 inset-x-0 py-3 bg-white text-neutral-900 border-t border-neutral-200 z-20">
            <div className="container flex items-center justify-between">
              {(PriceDetails.totalNights != null && PriceDetails.totalNightsFee != null && aroundBlockedDates == '') ?
                <span className="text-2xl font-semibold">
                  AED {Math.abs(PriceDetails.totalNightsFee / PriceDetails.totalNights).toFixed(2)}
                  <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                    /night
                  </span>
                </span> :
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                  Select dates
                </span>
              }
              <ButtonPrimary href="##">Reserve</ButtonPrimary>
            </div>
          </div>
        )}

        {/* OTHER SECTION */}
        {!isPreviewMode && (
          <div className="container py-24 lg:py-32">
            {/* SECTION 1 */}
            <div className="relative py-16">
              <BackgroundSection />
              {/* Areas - SECTION */}
              <LocationsSlider
                heading="Explore By Areas"
                subHeading="Check stays by top locations"
                className="Locations"
                itemClassName="LocationsCardItem"
                itemPerRow={5}
                sliderStyle="style1"
                uniqueClassName="AreaCardsSection"
              />
            </div>

            {/* SECTION */}

          </div>
        )}
      </div>
    </>
  );
};

function tConvert(time: any) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}
export default ListingStayDetailPage;
