import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface Perks {
  id: string;
  title: string;
  desc: string;
  image: string;
}

const PERKS: Perks[] = [
  {
    id: "1",
    title: `Relax And Feel At Home`,
    desc: "Receive the same hotel experience and perks in the comfort of a luxurious home",
    image:
      "https://images.unsplash.com/photo-1470376619031-a6791e534bf0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "4",
    title: `Stay Connected`,
    desc: "Free high-speed Wi-Fi in all our properties.",
    image:
      "https://images.unsplash.com/photo-1585692614093-62dab82e9d08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "3",
    title: `Be Entertained`,
    desc: "Entertainment will be on your hand tips, enjoy movies & TV series on OTT platforms at our properties.",
    image:
      "https://images.unsplash.com/photo-1613280194169-6bb2f32a6bfa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "2",
    title: `Stay Close`,
    desc: "Why stay far away from it all? Our selected properties are located in the cities prime locations close to all public transportation.",
    image:
      "https://images.unsplash.com/photo-1587809409950-a1385423a5af?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
];

const SectionPerks = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="A selection of pristine, expertly designed homes with high-end amenities and services"
      >
        ⛱ Perks of BeOurGuest
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {PERKS.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.image}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.title}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.desc}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionPerks;
