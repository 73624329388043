import React, { FC, Fragment, useContext, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import AvatarDropdown from "./AvatarDropdown";
import UserContext from "context/UserContext";
import { isLoggedIn } from "axios-jwt";
import LoginSignup from "containers/LoginSignup/LoginSignup";
import NcModal from "shared/NcModal/NcModal";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface MainNav1Props {
  isTop: boolean;
}



const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const { isOpenLoginSignupModal, closeLoginSignupModal, openLoginSignupModal } = useContext(UserContext)

  const renderLoginSignupModal = () => {
    return (
      <div>
        <Transition appear show={isOpenLoginSignupModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeLoginSignupModal}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-1/2"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-1/2 max-w-3xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >Login/SignUp</Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={() => {
                        closeLoginSignupModal()
                      }} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 pb-8 pt-2 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <LoginSignup closeLoginSignupModal={closeLoginSignupModal} />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };
  return (
    <>
      <div
        className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"
          }`}
      >
        <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
          <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
            <Logo />
            <Navigation />
          </div>
          <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center xl:flex space-x-1">
              <SwitchDarkMode />
              <div className="px-1" />
              {((isLoggedIn()) ?
                <AvatarDropdown /> :
                <ButtonPrimary onClick={openLoginSignupModal}>Login or Signup</ButtonPrimary>
              )}

            </div>
            <div className="flex items-center xl:hidden">
              <SwitchDarkMode />
              <div className="px-1" />
              {((isLoggedIn()) ?
                <AvatarDropdown /> :
                // <ButtonPrimary href="/login">Sign up</ButtonPrimary>
                <ButtonPrimary onClick={openLoginSignupModal}>Login or Signup</ButtonPrimary>
              )}
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
      {renderLoginSignupModal()}
    </>
  );
};

export default MainNav1;
