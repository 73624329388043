import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";

const BlogSingle = () => {
  const [isSingleBlogApiResponseNoResult, setisSingleBlogApiResponseNoResult] = useState(false);
  const navigate = useNavigate();

  let { post } = useParams();
    /* api call for single Blog posts*/
  const {data: getSinglePostData, isLoading: getSinglePostLoading, isSuccess: getSinglePostisSuccess } = useQuery(
    ['getSinglePost'], 
    async () => {
      try{
          const { data } = await axios.get('https://objects.bogportal.com/wp-json/wp/v2/posts/', {
          params: {
              ...( (post !== "") && {slug: post,}),
          },
          });
          let totalPosts = data.length;
          if(totalPosts===0){
            setisSingleBlogApiResponseNoResult(true);
            navigate("/404", { replace: true })
          }else if(totalPosts>=1){
            await axios.get('https://objects.bogportal.com/wp-json/postbase/views', {params: {id: data['0'].id}});
            return data['0'];
          }
      }
      catch (error) { 
          console.log(error); 
      }
    },
    {cacheTime: 3900000,}
    );
    //related posts query
    const {data: getRelatedPostData, isLoading: getRelatedPostLoading, isSuccess: getRelatedPostisSuccess } = useQuery(
      ['getRelatedPosts', getSinglePostisSuccess], 
      async() => {
        
        try{
            const { data } = await axios.get('https://objects.bogportal.com/wp-json/bogapi/v1/relatedposts', {
            params: {
                post_id: getSinglePostData?.id,
            },
            });
            //console.log("relatedPosts data",data); 
              return data;
        }
        catch (error) { 
            console.log("relatedPosts",error); 
        }
      },
      {cacheTime: 3900000,}
      );

    
    console.log("singlepost", getSinglePostData);
    console.log("relatedposts", getRelatedPostData);
    

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
        <CategoryBadgeList categories={getSinglePostData?.categories_details} />
          {getSinglePostisSuccess? 
          <h1
          className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
          title="Quiet ingenuity: 120,000 lunches and counting"
          dangerouslySetInnerHTML={{ __html: getSinglePostisSuccess && getSinglePostData?.title?.rendered}}></h1>:
          <><Skeleton count={1} height={25}  />
          <Skeleton count={1} height={25}  /></>}
          
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
            {getSinglePostisSuccess? 
              <Avatar
              containerClassName="flex-shrink-0"
              sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              imgUrl={getSinglePostisSuccess && getSinglePostData?.author_details?.image}
            />:
            <Skeleton borderRadius={50} count={1} height={50} width={50}/>}
              
              <div className="ml-3">
                <div className="flex items-center">
                  <span
                    className="block font-semibold"
                  >
                    {getSinglePostisSuccess && getSinglePostData?.author_details?.name || <Skeleton count={1} height={20} width={150}/>}
                  </span>
                </div>
                {getSinglePostisSuccess? 
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                  {moment(getSinglePostData?.modified, 'YYYY-MM-DD[T]HH:mm:ss').format("MMMM Do YYYY")}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                  {readingTime(getSinglePostData?.content?.rendered)}
                  </span>
                </div>:<div className="mt-[6px]"><Skeleton count={1} height={15} width={80}/></div>}
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        dangerouslySetInnerHTML={{ __html:getSinglePostisSuccess && getSinglePostData?.content?.rendered }}>
      </div>
    );
  };

  const renderTags = () => {
    
    return (
      
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <CategoryBadgeList categories={getSinglePostisSuccess && getSinglePostData?.categories_details} />
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar imgUrl={getSinglePostData?.author_details?.image} sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <span>{getSinglePostData?.author_details?.name}</span>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
            {getSinglePostData?.author_details?.bio}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (RelatedPOST: any, index:number) => {
    return (
      <div
        key={index}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={`/blog/${RelatedPOST?.slug}`} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={RelatedPOST?.featuredImgUrl}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name={RelatedPOST?.category} />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{RelatedPOST?.name}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {RelatedPOST?.author_name}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{moment(RelatedPOST?.lastmodified, 'YYYY-MM-DD[T]HH:mm:ss').format("MMMM Do YYYY")}</span>
          </div>
        </div>
        <Link to={`/blog/${RelatedPOST?.slug}`} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Single Blog || Booking React Template</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={getSinglePostisSuccess && getSinglePostData?.fimg_url}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {/* {renderCommentForm()} */}
        {/* {renderCommentLists()} */}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  */}
            {getRelatedPostData?.map((item: any, index:number)=>renderPostRelated(item, index))}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

function readingTime(content: any) {
  const text = content;
  const wpm = 225;
  const words = text?.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time + 'min read';
}


export default BlogSingle;
