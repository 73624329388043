import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "./WidgetTags";
import WidgetCategories from "./WidgetCategories";
import WidgetPosts from "./WidgetPosts";
import Card3 from "./Card3";
import Card13 from "./Card13";
import Card12 from "./Card12";
import Card3Small from "./Card3Small";
import twFocusClass from "utils/twFocusClass";
import Skeleton from "react-loading-skeleton";

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
const postsDemo: PostDataType[] = DEMO_POSTS.filter((_, i) => i > 7 && i < 14);
//
export interface SectionLatestPostsProps {
  posts?: any[];
  className?: string;
  postCardName?: "card3";
  isApiSuccess?:boolean;
  popularposts?: any[];
  categories?: any[];
  totalPages?: any;
  totalPosts?:any;
  handlePagination?:any;
  currentPage?:any;
  category?:string;
  paginationApiError?:string;
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  posts,
  postCardName = "card3",
  className = "",
  isApiSuccess=false,
  popularposts,
  categories,
  totalPages,
  totalPosts,
  handlePagination,
  currentPage,
  category,
}) => {
  
  const renderCard = (post: PostDataType) => {
    switch (postCardName) {
      case "card3":
        return <Card3 key={post.id} className="" post={post} />;

      default:
        return null;
    }
  };

  
  const renderPagination = (total_pages: number)=>{
    let pageItems: any[] = [];
    if(total_pages>=1){
      
      for(var i = 0; i < total_pages; i++) {
        pageItems.push(Math.max(i + 1, 0));
      }
      return pageItems;
    }
    return pageItems;
  }

  const results_details = () =>{
    return (
      <div className={`mb-3 lg:mb-4`}>
      {totalPosts &&
        <span className="block text-neutral-500 dark:text-neutral-400">
          <span>{(totalPosts)?
          `Total posts ${totalPosts} - ${currentPage} of ${totalPages} Pages`
          :`No posts found, Entered into wrong page`}</span>
        </span>
  }
    </div>
    );
  }
  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
            <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
              <div className={ "max-w-2xl"} >
                <h2 className={`text-3xl md:text-4xl font-semibold`}>
                  {category? handleCapitalCase(category?.replaceAll(/-/g, ' ')) : `Latest Articles 🎈`|| <Skeleton count={1} height={20}/>}
                </h2>
                {category? (
                  <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
                    {`Blog posts from ${handleCapitalCase(category?.replaceAll(/-/g, ' '))} category`}
                  </span>
                ):(
                  <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
                    Read the latest blog posts from BeOurGuest
                  </span>
                )}
              </div>
            </div>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
          {results_details()}
          {isApiSuccess ?
            posts?.map((post) => renderCard(post)):
            postsDemo.map((post) => renderCard(post))
          }
          </div>
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
          <nav className="nc-Pagination inline-flex space-x-1 text-base font-medium" >
              {(isApiSuccess) && 
                  renderPagination(totalPages).map((item, index)=>{
                    return <button
                      key={index}
                      onClick={()=>handlePagination(item)}
                      className={(currentPage == item)?
                        `inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`:
                        `inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
                    >
                      {item}
                    </button>
                })
              }
              </nav>
            {/* <Pagination />
            <ButtonPrimary>Show me more</ButtonPrimary> */}
          </div>
        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          {/* <WidgetTags /> */}
          <WidgetCategories categories={categories}/>
          <WidgetPosts posts={popularposts}/>
        </div>
      </div>
    </div>
  );
};

const handleCapitalCase = (mySentence: string | undefined)=>{
  return mySentence?.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export default SectionLatestPosts;
