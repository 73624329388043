import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionPerks";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPerks from "./SectionPerks";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About Us - Be Our Guest Dubai</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="As an officially licensed holiday homes operator in Dubai with the Department of Economic Development and the Department of Tourism and Commerce Marketing, BeOurGuest prides itself in its bespoke services."
        />

        <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left pt-20">
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
              Whether for business or leisure, vacation or short term rentals, we offer a variety of carefully, handpicked apartments and villas in selected prime locations ensuring that your experience with us is truly a memorable one. Our team will also be more than happy to help you enjoy what the city has to offer by organizing the best encounters and attractions suited to your personal interests. 
            </span>
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 py-8">
              Just inform us what it is you are looking for and we will organize it for you. If you are a home owner, we can maximize the potential of your property; enabling you to earn more as opposed to what you would receive on annual rental income. By managing the entire process for you, all you have to do is just enjoy sit back and enjoy the returns.
            </span>
        </div>

        <SectionPerks />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionStatistic />

        {/*<SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default PageAbout;
