import React, { FC, Fragment, useContext, useState } from "react";
import googleSvg from "images/Google.svg";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import UserContext from "context/UserContext";
import { Transition, Dialog, Tab } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Button from "shared/Button/Button";

export interface LoginSignupProps {
  className?: string;
  closeLoginSignupModal?: () => void;
}



const LoginSignup: FC<LoginSignupProps> = ({ className = "", closeLoginSignupModal = () => { } }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  /**Login states */
  const [isOpenResponseModal, setisOpenResponseModal] = useState(false);
  const closeResponseModal = () => setisOpenResponseModal(false);
  const openResponseModal = () => setisOpenResponseModal(true);
  const navigate = useNavigate();

  const [isLoginLoading, setisLoginLoading] = useState(false);
  const [loginEmail, setloginEmail] = useState(null);
  const [loginPassword, setloginPassword] = useState(null);
  const [loginErrors, setloginErrors] = useState({
    email: '',
  })
  const [loginResponse, setloginResponse] = useState<any>({
    status: '',
    message: '',
  })

  /**signup states */
  const [isOpenSignupModal, setisOpenSignupModal] = useState(false);
  const closeSignupModal = () => setisOpenSignupModal(false);
  const openSignupModal = () => setisOpenSignupModal(true);
  const [isSignupLoading, setisSignupLoading] = useState(false)

  const [signupResponse, setsignupResponse] = useState<any>({
    status: '',
    message: '',
  })
  const [signupFirstName, setsignupFirstName] = useState(null);
  const [signupLastName, setsignupLastName] = useState(null);
  const [signupEmail, setsignupEmail] = useState(null);
  const [signupPassword, setsignupPassword] = useState(null);
  const [signupConfirmPassword, setsignupConfirmPassword] = useState(null);
  const [signupErrors, setsignupErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmpassword: '',
  })

  const { loginUser, signupUser } = useContext(UserContext);

  /**Login handling start */
  const handleLoginChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errorstemp = loginErrors;

    switch (name) {
      case 'loginEmail':
        errorstemp.email =
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        setloginEmail(value);
        setloginErrors((prevState) => ({ ...prevState, [name]: errorstemp.email, }));
        break;
      case 'loginPassword':
        setloginPassword(value);
        break;
      default:
        break;
    }
  }

  const handleLogin = (e: any) => {

    e.preventDefault();
    if (validateLoginForm(loginErrors)) {
      console.info('Valid Form');
      setisLoginLoading(true);
      loginUser(loginEmail, loginPassword).then((res: any) => {
        console.log("login res", res)
        setisLoginLoading(false);
        if (res.status === 400) {
          setloginResponse((prevState: any) => ({ ...prevState, status: res.status, message: res.message, }));
          openResponseModal();
        } else if (res.status === 500) {
          setloginResponse((prevState: any) => ({ ...prevState, status: res.status, message: res.message, }));
          openResponseModal();
        } else if (res.status === 200) {
          closeLoginSignupModal();
          // navigate("/", { replace: true });
        }
      });
    } else {
      console.error('Invalid Form');
    }

    // window.location.reload();
  }

  const renderLoginModal = () => {
    return (
      <div>
        <Transition appear show={isOpenResponseModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeResponseModal}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-1/2"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-1/2 max-w-3xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium text-center">{
                          (loginResponse.status === 400) ?
                            `😕 ${loginResponse.message}`
                            : (loginResponse.status === 500) ?
                              "☹️ Login Failed, try again."
                              : "🙄"
                        }</h3>
                      </div>

                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={() => {
                        closeResponseModal()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Close
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };
  /**Login handling end */

  /**signup handling start */

  const handleSignupChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errorsSignuptemp = signupErrors;

    switch (name) {
      case 'signupFirstName':
        errorsSignuptemp.firstName =
          value.length < 3
            ? 'First Name must be at least 3 characters long!'
            : '';
        setsignupFirstName(value);
        setsignupErrors((prevState) => ({ ...prevState, [name]: errorsSignuptemp.firstName, }));
        break;
      case 'signupLastName':
        errorsSignuptemp.lastName =
          value.length < 1
            ? 'Last Name must be at least 1 characters long!'
            : '';
        setsignupLastName(value);
        setsignupErrors((prevState) => ({ ...prevState, [name]: errorsSignuptemp.lastName, }));
        break;
      case 'signupEmail':
        errorsSignuptemp.email =
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        setsignupEmail(value);
        setsignupErrors((prevState) => ({ ...prevState, [name]: errorsSignuptemp.email, }));
        break;
      case 'signupPassword':
        errorsSignuptemp.password =
          value.length < 8
            ? 'Password must be at least 8 characters long!'
            : '';
        setsignupPassword(value);
        setsignupErrors((prevState) => ({ ...prevState, [name]: errorsSignuptemp.password, }));
        break;
      case 'signupConfirmPassword':
        console.log(value);
        errorsSignuptemp.confirmpassword =
          (value !== signupPassword)
            ? 'Password did not match'
            : '';
        setsignupConfirmPassword(value);
        setsignupErrors((prevState) => ({ ...prevState, [name]: errorsSignuptemp.confirmpassword, }));
        break;
      default:
        break;
    }
  }

  const handleSignup = (e: any) => {
    e.preventDefault();
    if (validateSignupForm(signupErrors)) {
      console.info('Valid Form');
      setisSignupLoading(true);
      signupUser(signupFirstName, signupLastName, signupEmail, signupPassword, signupConfirmPassword).then((res: any) => {
        console.log("signupres", res)
        setsignupResponse((prevState: any) => ({ ...prevState, status: res.status, message: res.message, }));
        setisSignupLoading(false);
        openSignupModal();
        if (res.status === 200) {
          setTimeout(() => {
            //navigate("/", { replace: true });
            closeSignupModal();
            closeLoginSignupModal();
          }, 2000);
        }
      });
    } else {
      console.error('Invalid Form');
    }
  }

  console.log({ signupErrors })

  console.log("signupResponse", signupResponse)
  const renderSignupModal = () => {
    return (
      <div>
        <Transition appear show={isOpenSignupModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeSignupModal}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-1/2"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-1/2 max-w-3xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      {(signupResponse.status === 200) ?
                        "👏 Congratulations, you've signedup"
                        : (signupResponse.status === 400) ?
                          "🤨"
                          : (signupResponse.status === 500) ?
                            "☹️ Signup Failed"
                            : "🙄"
                      }
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={() => {
                        closeSignupModal()
                      }} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7 text-center">
                        <h3 className="text-xl font-base text-center">{signupResponse.message}</h3>
                        {/* {signupResponse.status===200 &&
                        <span className="px-4 py-2 sm:px-5" >Login to your account now</span>
                        } */}
                      </div>

                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={() => {
                        closeSignupModal()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Close
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  /**signup handling end */
  return (
    <div className={`nc-LoginSignup w-lg ${className}`} data-nc-id="LoginSignup">
      <Tab.Group selectedIndex={selectedTabIndex} onChange={setSelectedTabIndex}>
        <Tab.List className="flex justify-center">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                    ? "bg-neutral-800 text-white"
                    : "text-neutral-6000 dark:text-neutral-400"
                  }`}
              >
                Login
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${selected
                    ? "bg-neutral-800 text-white"
                    : " text-neutral-6000 dark:text-neutral-400"
                  }`}
              >
                <span className="mr-2.5">SignUp</span>
              </button>
            )}
          </Tab>
        </Tab.List>

        <Tab.Panels className="mt-6">
          <Tab.Panel className="space-y-5">
            <div className="max-w-lg mx-auto space-y-6">
              {/* <div className="grid gap-3">
                    social login here
                    </div>
                    <div className="relative text-center">
                        <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                        OR
                        </span>
                        <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                    </div> */}
              {/* FORM */}
              <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} action="#" method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Input
                    type="email"
                    name="loginEmail"
                    placeholder="example@example.com"
                    className="mt-1"
                    autoComplete="email"
                    onChange={handleLoginChange}
                  />
                  {loginErrors.email.length > 1 &&
                    <span className='text-sm error'>{signupErrors.email}</span>}
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                    <Link to="/forgot-pass" className="text-sm">
                      Forgot Password?
                    </Link>
                  </span>
                  <Input type="password" name="loginPassword" autoComplete="current-password" onChange={handleLoginChange} className="mt-1" />
                </label>
                <ButtonPrimary loading={isLoginLoading} type="submit">Continue</ButtonPrimary>
              </form>
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Don't have an account? {` `}
                <span className="cursor-pointer" onClick={() => setSelectedTabIndex(1)}>Sign Up</span>
              </span>
            </div>
          </Tab.Panel>
          <Tab.Panel className="space-y-5">
            {/**signup tab start */}
            <div className="max-w-lg mx-auto space-y-6 ">
              {/* <div className="grid gap-3">
                        <a
                            href="#"
                            className="nc-will-change-transform flex w-full rounded-lg bg-secondary-300 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                        >
                            <img
                            className="flex-shrink-0"
                            src={googleSvg}
                            alt="signup with google"
                            />
                            <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                            Continue with Google
                            </h3>
                        </a>
                    </div>
                    <div className="relative text-center">
                        <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                        OR
                        </span>
                        <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                    </div> */}
              {/* FORM */}
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSignup} action="#" method="post" >
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      First Name
                    </span>
                    <Input
                      type="text"
                      name="signupFirstName"
                      placeholder="First Name"
                      className="mt-1"
                      onChange={handleSignupChange}
                    />
                    {signupErrors.firstName.length > 1 &&
                      <span className='text-sm error'>{signupErrors.firstName}</span>}
                  </label>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Last Name
                    </span>
                    <Input
                      type="text"
                      name="signupLastName"
                      placeholder="Last Name"
                      className="mt-1"
                      onChange={handleSignupChange}
                    />
                    {signupErrors.lastName.length > 1 &&
                      <span className='text-sm error'>{signupErrors.lastName}</span>}
                  </label>
                </div>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Input
                    type="email"
                    name="signupEmail"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={handleSignupChange}
                  />
                  {signupErrors.email.length > 1 &&
                    <span className='text-sm error'>{signupErrors.email}</span>}
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                  <label className="block">
                    <span className="loading flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      Password
                    </span>
                    <Input type="password" name="signupPassword" className="mt-1" onChange={handleSignupChange} />
                    {signupErrors.password.length > 1 &&
                      <span className='text-sm error'>{signupErrors.password}</span>}
                  </label>
                  <label className="block">
                    <span className="loading flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      Confirm Password
                    </span>
                    <Input type="password" name="signupConfirmPassword" className="mt-1" onChange={handleSignupChange} />
                    {signupErrors.confirmpassword.length > 1 &&
                      <span className='text-sm error'>{signupErrors.confirmpassword}</span>}
                  </label>
                </div>
                <ButtonPrimary loading={isSignupLoading} type="submit">Continue</ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Already have an account? {` `}
                <span className="cursor-pointer" onClick={() => setSelectedTabIndex(0)}>Sign in</span>
              </span>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {renderLoginModal()}
      {renderSignupModal()}
    </div>
  );
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateLoginForm = (errors: { email: string }) => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const validateSignupForm = (errors: { firstName: string, lastName: string, email: string, password: string, confirmpassword: string }) => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

export default LoginSignup;