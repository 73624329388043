import React, { FC } from "react";
import PropertyListingGallerySlider from "components/PropertListingGallerySlider/PropertListingGallerySlider";
import { PROPERTY_LISTINGS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { ListingCardArray, ListingCardDataType } from "data/types";
import Skeleton from "react-loading-skeleton";
import ncNanoId from "utils/ncNanoId";
import moment from "moment";

export interface PropertyCardHProps {
  className?: string;
  data: ListingCardArray;
  urlMetaData?: any;
}

const STATIC_PROEPRTY_LISTING_DATA = PROPERTY_LISTINGS.response[0];

const PropertyCardH: FC<PropertyCardHProps> = ({
  className = "",
  data = STATIC_PROEPRTY_LISTING_DATA,
  urlMetaData,
}) => {

  const {
    address,
    bathroomsNumber,
    bedroomsNumber,
    bedsNumber,
    featured,
    propertyId,
    listingImages,
    name,
    personCapacity,
    price,
    propertyTypeName,
    roomType,
    slug,
    suburb,
  } = data;

  let urlMeta = '';
  
  if ((urlMetaData?.start_date != null && urlMetaData?.end_date != null) && (urlMetaData?.start_date != "" && urlMetaData?.end_date != "")) {
    urlMeta = `?sd=${moment(urlMetaData?.start_date, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")}&ed=${moment(urlMetaData?.end_date, 'YYYY-MM-DD[T]HH:mm:ss').format("YYYY-MM-DD")}&guests=${urlMetaData?.guests_count}`;
  }

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <PropertyListingGallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={listingImages}
          className="w-full h-full rounded-2xl overflow-hidden"
          uniqueID={`PropertyCardH_${propertyId}`}
        />


        {featured && (
          <SaleOffBadge className="absolute left-5 text-xs top-5 !bg-orange-500" desc={"Featured"} />
        )}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-1">
          <span className="hidden sm:inline-block">
            <i className="las la-user text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {personCapacity} guests
          </span>
        </div>

        <div className="flex items-center space-x-1">
          <span className="hidden sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {(bedroomsNumber == 0) ? `studio` : `${bedroomsNumber} bedroom`}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-1">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {/* {(bedroomsNumber==0)? `Studio` : `${bedroomsNumber} bedrooms`} */}
            {bathroomsNumber} Bath
          </span>
        </div>

        {/* ---- 
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            1200 Sq. Fit
          </span>
        </div>*/}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-building"></i>
                  <span className="ml-1">{(bedroomsNumber == 0) ? `Studio` : `${propertyTypeName}`}</span>
                </div>
              }
            />
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-home"></i>
                  <span className="ml-1">{handleCapitalCase(roomType)}</span>
                </div>
              }
              color="yellow"
            />
          </div>
          <div className="flex items-center space-x-2">
            {/*isAds && <Badge name="ADS" color="green" />*/}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{name.slice(name.indexOf('·') + 1)}</span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <div className="flex items-center space-x-2">
              <span className="hidden sm:inline-block">
                <i className="las la-map-marker text-xl"></i>
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {suburb}
              </span>
            </div>
            {/*<StartRating reviewCount={reviewCount} point={reviewStart} />*/}
            {(price != null) && <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-sm font-medium text-secondary-500">
              {`${price}/night`}
            </span>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {(name != "") ?
        <div
          className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
          data-nc-id="PropertyCardH"
        >
          <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
            {renderSliderGallery()}
            <Link to={`/listing/${slug}${urlMeta}`}>{renderContent()}</Link></div>
          <BtnLikeIcon
            colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
            className="absolute right-5 top-5 sm:right-3 sm:top-3 "
          />
        </div> :
        <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
            <Skeleton height={200} />
          </div>
          <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
            <Skeleton height={15} width={200} />
            <Skeleton height={40} width={300} />
            <Skeleton height={20} width={150} />
            <Skeleton height={30} width={250} />
          </div>
        </div>

      }
    </>
  );
};

const handleCapitalCase = (mySentence: string | undefined) => {
  return mySentence?.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}
export default PropertyCardH;
